var Aneox;
(function (Aneox) {
  'use strict';

  var Wines = (function () {

    var self;

    var animations;
    var isOpen = false;
    var scroll = "";

    var grandCruWinesListElement;
    var premierCruWinesListElement;
    var villageWinesListElement;
    var bourgogneWinesListElement;
    var regionaleWinesListElement;
    
    var sheetElement;

    var currentWineId = "";
    var nextWineIndex = "";
    var previousWineIndex = "";
    
    var dataGrandCru;
    var dataPremierCru;
    var dataVillage;
    var dataBourgogne;
    var dataRegionale;
    var dataAll;
    var dataAllOrdered;

    var bodyHeight;
    var bodyWidth;

    var localData;

    var activeSection;

    function Wines(animations, locale, router) {
    

      this.winesHightLight = {};
     
      this.locale = locale;
      this.anims = animations.timeline
      this.router = router;
      this.winesFormVineyardFilter = {};

      self = this;

      grandCruWinesListElement = $('#lesGrandsCrus .liste');
      premierCruWinesListElement = $('#lesPremiersCrus .liste');
      villageWinesListElement = $('#village .liste');
      bourgogneWinesListElement = $('#bourgognes .liste');
      regionaleWinesListElement = $('#regionales .liste');
  

      sheetElement = $('#ficheVin');

      dataAll = Aneox.Data.Data.wine;
      dataAllOrdered = _.chain(dataAll).orderBy(function(value){return parseInt(value.globalOrder)}, 'asc').value();
     
      dataGrandCru = _.chain(dataAll).filter({level: "grandscrus"}).orderBy(function(value){return parseInt(value.order)}, 'asc').value();
      dataPremierCru = _.chain(dataAll).filter({level: "premiercru"}).orderBy(function(value){return parseInt(value.order)}, 'asc').value();
      dataVillage = _.chain(dataAll).filter({level: "village"}).orderBy(function(value){return parseInt(value.order)}, 'asc').value();
      dataBourgogne = _.chain(dataAll).filter({level: "bourgogne"}).orderBy(function(value){return parseInt(value.order)}, 'asc').value();
      dataRegionale = _.chain(dataAll).filter({level: "regionale"}).orderBy(function(value){return parseInt(value.order)}, 'asc').value();

      bodyWidth = $(window).innerWidth();
      bodyHeight = $(window).innerHeight();


    }

    
    Wines.prototype.showWinesList = function(){
        var timeline = new TimelineMax();

        $(".listesVins").removeClass('ficheVin');
    }

    Wines.prototype.show = function(wineId){
      if(isOpen) return;
      isOpen = true;

      scroll = $('#ficheVin .details .scrollable');

      self.populateWine(wineId);
      currentWineId = wineId;

       
      var timeline = new TimelineMax();

      
      timeline.set(sheetElement, {display: 'block', x:0, Y:0});
      timeline.fromTo(sheetElement, .5, { opacity: 0}, { opacity: 1});
     
      timeline.call(function(){
        
      });


      self.trigger('wineWillShow');

    }


    Wines.prototype.swapWine = function(wineId){
          
        self.populateWine(wineId);
        currentWineId = wineId;

        try{
          scroll.data("mCS") && scroll.mCustomScrollbar("destroy"); //Destroy
        }catch (e){
          scroll.data("mCS",''); //Manual Destroy
        }
  
        scroll.mCustomScrollbar({
          theme: 'rounded-dots',
          scrollbarPosition: 'outside'
      });   
    }

    Wines.prototype.populateWine = function(wineId){


      var dataWine= _.find(Aneox.Data.Data.wine, {'id': wineId});
      self.localeData = _.find(dataWine.locale, {'locale': self.locale.getLocale()});
      // var wineType = dataWine.type;

      $(".appellation", sheetElement)
      .empty()
      .append(dataWine.name);

      $(".level", sheetElement)
      .empty()
      .append(dataWine.name2);


      setVintageList(wineId);
      updateVintage(self.localeData.vintage[0].year);

      var currentWineIndex = _.findIndex(dataAllOrdered, {id : wineId});
      
      if(currentWineIndex == dataAllOrdered.length-1){
        nextWineIndex = 0;
        previousWineIndex = dataAllOrdered.length-1
      }else if(currentWineIndex == 1){
        previousWineIndex = 0;
        nextWineIndex = currentWineIndex + 1;
      }
      else if(currentWineIndex == 0){
        previousWineIndex = dataAllOrdered.length-1;
        nextWineIndex = currentWineIndex + 1;
      }
      else{
        nextWineIndex = currentWineIndex + 1;
        previousWineIndex = currentWineIndex - 1;

      }

      var nextWineURL = self.router.getNodePath(dataAllOrdered[nextWineIndex].id, self.locale.getLocale());
      var previousWineURL = self.router.getNodePath(dataAllOrdered[previousWineIndex].id, self.locale.getLocale());

      $(".carousel-next", sheetElement).attr('href', nextWineURL);
      $(".carousel-prev", sheetElement).attr('href', previousWineURL);
      
    }

    Wines.prototype.close = function(){
      if(!isOpen) return;

      // TweenMax.fromTo(sheetElement, .5, { opacity: 1, y: 0}, { opacity: 0, y: -bodyHeight / 2, onComplete: function () {
      TweenMax.fromTo(sheetElement, .5, { opacity: 1, y: 0}, { opacity: 0, y: 0, onComplete: function () {
        scroll.mCustomScrollbar('destroy');
        sheetElement.css('display', 'none');
        isOpen = false;
        
        self.trigger('wineWillHide');
        currentWineId = null;
      } });      
    }

    Wines.prototype.setWinesList = function(){

      premierCruWinesListElement.empty();
      grandCruWinesListElement.empty();
      villageWinesListElement.empty();
      bourgogneWinesListElement.empty();
      regionaleWinesListElement.empty();


      var grandCruFragement = $(document.createDocumentFragment());
      var premierCruFragement = $(document.createDocumentFragment());
      var villageFragement = $(document.createDocumentFragment());
      var bourgogneFragement = $(document.createDocumentFragment());
      var regionaleFragment = $(document.createDocumentFragment());

      var i = 0;


      //append grand cru
      _.forEach(dataGrandCru, function(wine){
        i++;

        grandCruFragement.append('<li class="item"><a id="wine-list-'+ wine.id +'" class="btnFiche" router-href="'+ wine.id +'" >'+ wine.nameList +'</a></li>');
      });

      grandCruWinesListElement.append(grandCruFragement);

      //append premier cru
      _.forEach(dataPremierCru, function(wine){
        premierCruFragement.append('<li class="item"><a id="wine-list-'+ wine.id +'" class="btnFiche" router-href="'+ wine.id +'" translate>'+ wine.nameList +'</a></li>');
      });

      premierCruWinesListElement.append(premierCruFragement);


      //append village
      _.forEach(dataVillage, function(wine){
        villageFragement.append('<li class="item"><a id="wine-list-'+ wine.id +'" class="btnFiche" router-href="'+ wine.id +'" translate>'+ wine.nameList +'</a></li>');
      });

      villageWinesListElement.append(villageFragement);

      //append bourgogne
      _.forEach(dataBourgogne, function(wine){
        bourgogneFragement.append('<li class="item"><a id="wine-list-'+ wine.id +'" class="btnFiche" router-href="'+ wine.id +'" translate>'+ wine.nameList +'</a></li>');
      });

      bourgogneWinesListElement.append(bourgogneFragement);

      //append regionale
      _.forEach(dataRegionale, function(wine){
        regionaleFragment.append('<li class="item"><a id="wine-list-'+ wine.id +'" class="btnFiche" router-href="'+ wine.id +'" translate>'+ wine.nameList +'</a></li>');
      });

      regionaleWinesListElement.append(regionaleFragment);

      self.router.reparseAnchors();
    }



  var hoverAppellation = function(wineId){

  }

  var outAppellation = function(wineId){
    
  }

  var updateVintage = function(vintage){
    if(_.isEmpty(vintage)) return;

    var dataVintage = _.find(self.localeData.vintage, { year: vintage});

    var bottleElement = $(".bottle", sheetElement)
    .hide()
    .attr('src',dataVintage.photo)
    .show();

    var wineElement = $(".data-wine", sheetElement)
    .hide()
    .empty()
    .append('<p class="para">'+ dataVintage.description +'</p>');
    if(!_.isEmpty(dataVintage.grape))
    wineElement.append('<p class="info">'+ self.locale.getTranslation('Cépage :', self.locale.getLocale()) +' <span class="span">'+ dataVintage.grape +'</span></p>')
    
    if(!_.isEmpty(dataVintage.area))
    wineElement.append('<p class="info">'+ self.locale.getTranslation('Superficie:', self.locale.getLocale()) +' <span class="span">'+ dataVintage.area +'</span></p>')
    
    if(!_.isEmpty(dataVintage.age))
    wineElement.append('<p class="info">'+ self.locale.getTranslation('Âge de la vigne:', self.locale.getLocale()) +' <span class="span">'+ dataVintage.age +'</span></p>')
    
    if(!_.isEmpty(dataVintage.density))
    wineElement.append('<p class="info">'+ self.locale.getTranslation('Densité de plantation:', self.locale.getLocale()) +' <span class="span">'+ dataVintage.density +'</span></p>')
    
    if(!_.isEmpty(dataVintage.soil))
    wineElement.append('<p class="info">'+ self.locale.getTranslation('Sol :', self.locale.getLocale()) +' <span class="span">'+ dataVintage.soil +'</span></p>')
    
    if(!_.isEmpty(dataVintage.tasting))
    wineElement.append('<p class="info">'+ self.locale.getTranslation('Notes de dégustaion :', self.locale.getLocale()) +' <span class="span d-block">'+ dataVintage.tasting +'</span></p>')
    
    wineElement.show();

    try{
      scroll.data("mCS") && scroll.mCustomScrollbar("destroy"); //Destroy
    }catch (e){
      scroll.data("mCS",''); //Manual Destroy
    }

    scroll.mCustomScrollbar({
      theme: 'rounded-dots',
      scrollbarPosition: 'outside'
  });   
    
  }

  var setVintageList = function(current){
    var dropdown = $('#catVins').empty(); 

    _.each(self.localeData.vintage,function(vintage){
      dropdown.append('<option value="'+ vintage.year +'">'+ self.locale.getTranslation("Millésime", self.locale.getLocale()) +' '+ vintage.year  +'</option>');
    });

    dropdown.each(function () {
      var $this = $(this),
      numberOfOptions = $(this).children("option").length;
    
      $this.addClass("select-hidden");
      $this.wrap('<div class="select"></div>');
      $this.after('<div class="select-styled"></div>');
    
      var $styledSelect = $this.next("div.select-styled");
      $styledSelect.text($this.children("option").eq(0).text());



    
      var $list = $("<ul />", {
        class: "select-options" }).
      insertAfter($styledSelect);
    
      for (var i = 0; i < numberOfOptions; i++) {
        $("<li />", {
          text: $this.children("option").eq(i).text(),
          rel: $this.children("option").eq(i).val() }).
        appendTo($list);
      }
    
      var $listItems = $list.children("li");
    
      $styledSelect.click(function (e) {
        e.stopPropagation();
        $("div.select-styled.active").
        not(this).
        each(function () {
          $(this).removeClass("active").next("ul.select-options").hide();
        });
        $(this).toggleClass("active").next("ul.select-options").toggle();
      });
    
      $listItems.click(function (e) {
        e.stopPropagation();
        $styledSelect.text($(this).text()).removeClass("active");
        $this.val($(this).attr("rel"));
        $list.hide();
        updateVintage($(this).attr("rel"));
      });
    
      $(document).click(function () {
        $styledSelect.removeClass("active");
        $list.hide();
      });
    });

    dropdown.on('click',function(e){
      e.stopPropagation();
      var vintage = e.rel
      swapWine(vintage);
    });


  }

  Wines.prototype.handleWindowResize = function(width, height) {
      
      if (isOpen) {

        bodyHeight = height;
        bodyWidth = width;


      }
    }

    MicroEvent.mixin(Wines);    

    return Wines;
  })();

  Aneox.Wines = Wines;
})(Aneox || (Aneox = {}));