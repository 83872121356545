var Aneox;
(function (Aneox) {
  (function (Sequences) {
    'use strict';

    var Estate = (function () {

      var self;

      var estate;
      var estateVisibility;
      var estateMast;
      
      var hasPlayedThroughAnimation;

      function Estate(animations, resources) {
        self = this;
        this.animations = animations;
        this.resources = resources;
        
        hasPlayedThroughAnimation = {};
        
        estate = $('#estate');
        estateMast = $('#estate-mast');
        
        var observer = new MutationObserver(function (mutations) {
          mutations.forEach(function (mutation) {
            if (mutation.attributeName === 'style') {
              var display = estate.css('display');
              var previousEstateVisibility = estateVisibility;
              estateVisibility = display !== 'none';
              
              if (!previousEstateVisibility && display !== 'none') {
                self.handleWindowResize();
              }
            }
          });
        });
        observer.observe(estate[0], { attributes: true });
        
        this.resetAnimations();
      }
      
      Estate.prototype.handleWindowResize = function (bodyWidth, bodyHeight) {
        // if (!domaineVisibility) return;
      };
      
      Estate.prototype.resetAnimations = function () {
        hasPlayedThroughAnimation = {};

        //mast
        self.animations.initSectionIntroTimeline($('#estate'));

        //part 2 
        $('.frame', estate).removeClass('active');

        //part 3
        self.animations.initSubtitleTimeline($('#estate .a3 .ttr'));
        

        //part 4
        TweenMax.set('#estate .a3 .imgContent img', { opacity: 0, force3D: true, immediateRender: true });
        TweenMax.set('#estate .a3 .imgContent svg', { opacity: 0, force3D: true, immediateRender: true });
      };
      
      Estate.prototype.fastForwardAnimations = function () {
        this.resetAnimations();
        this.mastEnterAnimation(true, 0);
        this.firstPartEnterAnimation(true, 0);
        this.secondPartEnterAnimation(true, 0);
        this.thirdPartEnterAnimation(true, 0);
      };
      
      Estate.prototype.mastEnterAnimation = function (force, timescale) {
        if (!force && (!self.animations.enabled || hasPlayedThroughAnimation.mast)) return;
        
        if (timescale === undefined) timescale = 1;

        var timeline = new TimelineMax();

        timeline.call(this.animations.SectionIntroTimeline, [$('#estate')], self.animations, 0 * timescale);
        

        hasPlayedThroughAnimation.mast = true;
      };


      Estate.prototype.firstPartEnterAnimation = function (force, timescale) {
        if (!force && (!self.animations.enabled || hasPlayedThroughAnimation.first)) return;
        
        if (timescale === undefined) timescale = 1;

        var timeline = new TimelineMax();

        timeline.call(function(){
          $('.frame', estate).addClass('active');
        }, self.animations, 0 * timescale);

        hasPlayedThroughAnimation.first = true;
      };

      Estate.prototype.secondPartEnterAnimation = function (force, timescale) {
        if (!force && (!self.animations.enabled || hasPlayedThroughAnimation.second)) return;
        
        if (timescale === undefined) timescale = 1;
        
        var timeline = new TimelineMax();
        
        timeline.call(this.animations.SubtitleTimeline, [$('#estate .a3 .ttr')], self.animations,0 * timescale);


        hasPlayedThroughAnimation.second = true;
      };

      Estate.prototype.thirdPartEnterAnimation = function (force, timescale) {
        if (!force && (!self.animations.enabled || hasPlayedThroughAnimation.third)) return;
        
        if (timescale === undefined) timescale = 1;
        
        var timeline = new TimelineMax();
        
        timeline.to("#estate .a3 .imgContent img", 1, {opacity: 1, force3D: true, delay: 0 * timescale});
        timeline.to("#estate .a3 .imgContent svg", 1, {opacity: 1, force3D: true, delay: -.5 * timescale});

        hasPlayedThroughAnimation.third = true;
      };


      return Estate;
    })();

    Sequences.Estate = Estate;
  })(Aneox.Sequences || (Aneox.Sequences = {}));
})(Aneox || (Aneox = {}));