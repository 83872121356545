var Aneox;
(function (Aneox) {
  (function (Sequences) {
    'use strict';

    var Main = (function () {
      
      var initialized;

      function Main(animations) {
        this.animations = animations;
        
        this.timeline = new TimelineMax({ paused: true });
        this.scrollHeight = 0;
        this.duration = 0;
        this.keyframes = {};
      }
      
      Main.prototype.handleWindowResize = function (bodyWidth, bodyHeight) {
        
        var previousProgress = this.timeline.progress();
        
        TweenMax.killTweensOf(this.timeline);
        this.timeline.clear();

        var isTouchDevice = 'ontouchstart' in window;
        var referenceScrollSpeed = isTouchDevice ? 1.0 : 0.25;
        var slowScrollSpeed = isTouchDevice ? 1.0 : 0.15;


        var slowPartCoef = 1.2;
        var coefTrigger = bodyHeight * 0.00034;
        
        // Precompute heights and timings
        var currentTime = 0;

        this.keyframes.home = 0;
        
        // Home

        //Animations
        this.timeline.call(this.animations.sequences.home.mastEnterAnimation, null, this.animations.sequences.home, currentTime);


        this.timeline.fromTo('#home', 1, { display: 'block' }, { display: 'block', clearProps: 'display,zIndex', ease: ease, force3D: force3D }, currentTime);
        this.timeline.set('#estate',  { clearProps: 'display' }, currentTime);
        this.timeline.to('#estate', 1, { display: 'block', ease: ease, force3D: force3D }, currentTime);

        var ease = Linear.easeNone;
        var force3D = true;
        
        // Home
        var homeMastHeight = ($("#home-mast").innerHeight() - 150) / bodyHeight;
        var homePart1Height = ($("#home-part1").innerHeight()) / bodyHeight;
        var homeWrapperHeight = $('#home-wrapper').innerHeight() / bodyHeight;
        var homeWrapperScrollHeight = homeMastHeight + homePart1Height;
        // var homeWrapperScrollHeight = homeWrapperHeight;

        this.timeline.fromTo('#home-wrapper', 1 , { y: 0 }, { y: -bodyHeight * (homeWrapperScrollHeight), ease: ease, force3D: force3D }, currentTime);
        this.timeline.fromTo('#estate-wrapper', 1 , { y: bodyHeight * (homeWrapperScrollHeight)}, { y: 0, ease: ease, force3D: force3D }, currentTime);
      
        // Animation
        this.timeline.fromTo('.home .bg', .75, {y:0}, {y:-350, ease: ease, force3D: force3D}, currentTime);
        this.timeline.fromTo('.home .article', .75, {y:0}, {y:-650, ease: ease, force3D: force3D}, currentTime);


        // currentTime += homeWrapperScrollHeight - 0.05;
        currentTime += 1;
        this.keyframes.estate = currentTime;


        // Estate
        var estateMastHeight = $("#estate-mast").innerHeight() / bodyHeight;
        var estatePart1Height = $("#estate-part1").innerHeight() / bodyHeight;
        var estatePart2Height = ($("#estate-part2").innerHeight() + 212) / bodyHeight;
        // var estatePart2Height = ($("#estate-part2").innerHeight()) / bodyHeight;
        var estateWrapperHeight = $("#estate-wrapper").innerHeight() / bodyHeight;

        var estateWrapperScrollHeight = estateMastHeight + estatePart1Height + estatePart2Height;
        // var estateWrapperScrollHeight = estateWrapperHeight - 1;


        // Estate to philosophy
        this.timeline.to('#estate', 1, { display: 'block', clearProps: 'display', ease: ease, force3D: force3D }, currentTime);
        this.timeline.set('#philosophy',  { clearProps: 'display' }, currentTime);
        this.timeline.to('#philosophy', 1, { display: 'block', ease: ease, force3D: force3D }, currentTime);

        this.timeline.fromTo('#estate-wrapper', 1 , { y: 0 }, { y: -bodyHeight * (estateWrapperScrollHeight), ease: ease, force3D: force3D }, currentTime);
        this.timeline.fromTo('#philosophy-wrapper', 1 , { y: bodyHeight * (estateWrapperScrollHeight) }, { y: 0, ease: ease, force3D: force3D }, currentTime);

        // Animation
        this.timeline.call(this.animations.sequences.estate.mastEnterAnimation, null, this.animations.sequences.estate, currentTime - .25);
        this.timeline.fromTo('#estate .a2 .content .imgContent', .75, {y:200}, {y:-200, ease: ease, force3D: force3D}, currentTime);
        this.timeline.fromTo('#estate .a2 .content .resume', .5, {y:300}, {y:-300, ease: ease, force3D: force3D}, currentTime);
        this.timeline.call(this.animations.sequences.estate.firstPartEnterAnimation, null, this.animations.sequences.estate, currentTime + .25);
        this.timeline.fromTo('#estate .a3 .slide img', .5, {y:150}, {y:-150, ease: ease, force3D: force3D}, currentTime + .25);
        this.timeline.call(this.animations.sequences.estate.secondPartEnterAnimation, null, this.animations.sequences.estate, currentTime + .35);
        this.timeline.call(this.animations.sequences.estate.thirdPartEnterAnimation, null, this.animations.sequences.estate, currentTime + .5);

        // this.timeline.fromTo('#estate-wrapper', 1, { y: -bodyHeight * introWrapperScrollHeight }, { y: -bodyHeight * (introWrapperScrollHeight + 1), ease: ease, force3D: force3D }, currentTime);
        // this.timeline.fromTo('#philosophy-wrapper', 1, { y: bodyHeight }, { y: 0, ease: ease, force3D: force3D }, currentTime);

        // currentTime += estateWrapperScrollHeight;
        currentTime += 1;
        this.keyframes.philosophy = currentTime;

        // philosophy
        var philosophyMastHeight = ($('#philosophy-mast').innerHeight())  / bodyHeight;
        var philosophyPart1Height = $('#philosophy-part1').innerHeight() / bodyHeight;
        var philosophyPart2Height = $('#philosophy-part2').innerHeight() / bodyHeight;

        var philoHeight = $('#philosophy-mast').innerHeight() + $('#philosophy-part1').innerHeight() + $('#philosophy-part2').innerHeight();
        var philoHeightWrapper = $('#philosophy-wrapper').innerHeight();

        var philosophyWrapperScrollHeight = philosophyMastHeight + philosophyPart1Height + philosophyPart2Height;

        // console.log(philoHeight, philoHeightWrapper, estateWrapperScrollHeight, philosophyWrapperScrollHeight);

        // philosophy to vignard
         this.timeline.to('#philosophy', 1, { display: 'block', clearProps: 'display', ease: ease, force3D: force3D }, currentTime);
         this.timeline.set('#vignard',  { clearProps: 'display' }, currentTime);
         this.timeline.to('#vignard', 1, { display: 'block', ease: ease, force3D: force3D }, currentTime);

        this.timeline.fromTo('#philosophy-wrapper', 1 , { y: 0 }, { y: -bodyHeight * (philosophyWrapperScrollHeight), ease: ease, force3D: force3D }, currentTime);
        this.timeline.fromTo('#vignard-wrapper', 1 , { y: bodyHeight * (philosophyWrapperScrollHeight)}, { y: 0, ease: ease, force3D: force3D }, currentTime);
      
        // Animation
        this.timeline.call(this.animations.sequences.philosophy.mastEnterAnimation, null, this.animations.sequences.philosophy, currentTime - .1);
        this.timeline.fromTo('#philosophy .a2 .content .pic', .75, {y:200}, {y:-200, ease: ease, force3D: force3D}, currentTime);
        this.timeline.fromTo('#philosophy .a2 .content .resume', .5, {y:300}, {y:-300, ease: ease, force3D: force3D}, currentTime);
        this.timeline.call(this.animations.sequences.philosophy.secondPartEnterAnimation, null, this.animations.sequences.philosophy, currentTime + .15);
        this.timeline.fromTo('#philosophy .a3 .content .resume', .5, {y:300}, {y:-300, ease: ease, force3D: force3D}, currentTime + .5);


        currentTime += 1;
        this.keyframes.vignard = currentTime;

        // vignard
        var vignardMastHeight = $("#vignard-mast").innerHeight() / bodyHeight;
        var vignardPart1Height = $("#vignard-part1").innerHeight() / bodyHeight;
        var vignardPart2Height = $("#vignard-part2").innerHeight() / bodyHeight;
        var vignardPart3Height = $("#vignard-part3").innerHeight() / bodyHeight;
        var vignardPart4Height = ($("#vignard-part4").innerHeight() + 200) / bodyHeight;

        // console.log($("#vignard-mast").innerHeight(), $("#vignard-part1").innerHeight(), $("#vignard-part2").innerHeight(), $("#vignard-part3").innerHeight(), $("#vignard-part4").innerHeight());

        var vignardWrapperScrollHeight = vignardMastHeight + vignardPart1Height + vignardPart2Height + vignardPart3Height + vignardPart4Height

         // vignard to wines
         this.timeline.to('#vignard', 1, { display: 'block', clearProps: 'display', ease: ease, force3D: force3D }, currentTime);
         this.timeline.set('#wines',  { clearProps: 'display' }, currentTime);
         this.timeline.to('#wines', 1, { display: 'block', ease: ease, force3D: force3D }, currentTime);

        this.timeline.fromTo('#vignard-wrapper', 1 , { y: 0 }, { y: -bodyHeight * (vignardWrapperScrollHeight), ease: ease, force3D: force3D }, currentTime);
        this.timeline.fromTo('#wines-wrapper', 1 , { y: bodyHeight * (vignardWrapperScrollHeight)}, { y: 0, ease: ease, force3D: force3D }, currentTime);
      
        // Animation
        this.timeline.call(this.animations.sequences.vignard.mastEnterAnimation, null, this.animations.sequences.vignard, currentTime - .15);
        // this.timeline.fromTo('#vignard .a2 .content .pic', .25, {opacity:0}, {opacity:1, ease: ease, force3D: force3D}, currentTime);
        this.timeline.fromTo('#vignard .a2 .content .resume', .5, {y:300}, {y:-300, ease: ease, force3D: force3D}, currentTime - .15);
        this.timeline.call(this.animations.sequences.vignard.secondPartEnterAnimation, null, this.animations.sequences.vignard, currentTime + .15);
        this.timeline.fromTo('#vignard .a3 .bg img', .5, {y:150}, {y:-150, ease: ease, force3D: force3D}, currentTime + .15);
        this.timeline.call(this.animations.sequences.vignard.thirdPartEnterAnimation, null, this.animations.sequences.vignard, currentTime + .25);
        this.timeline.fromTo('#vignard .a3 .content', .5, {y:220}, {y:-250, ease: ease, force3D: force3D}, currentTime);
        
        this.timeline.fromTo('#vignard .a4 .pic-1', .5, {y:300}, {y:-300, ease: ease, force3D: force3D}, currentTime + .25);
        this.timeline.fromTo('#vignard .a4 .pic-2', .5, {y:200}, {y:-200, ease: ease, force3D: force3D}, currentTime + .27);
        this.timeline.fromTo('#vignard .a4 .pic-3', .5, {y:100}, {y:-100, ease: ease, force3D: force3D}, currentTime + .29);
        // this.timeline.fromTo('#vignard .a4 .pic-4, #vignard .a4 .pic-5, #vignard .a4 .content', .5, {y:200}, {y:-200, ease: ease, force3D: force3D}, currentTime + .31);
        
        
        // this.timeline.fromTo('#vignard .a5 .bg', .5, {y:200}, {y:-200, ease: ease, force3D: force3D}, currentTime + .75);
        this.timeline.call(this.animations.sequences.vignard.fourthPartEnterAnimation, null, this.animations.sequences.vignard, currentTime + .75);
        this.timeline.fromTo('#vignard .a5 .content', .25, {y:300}, {y:-300, ease: ease, force3D: force3D}, currentTime + .75);

        currentTime += 1;
        this.keyframes.wines = currentTime;


        // wines
        var winesMastHeight = $("#wines-mast").innerHeight() / bodyHeight;
        var winesMastPart1Height = $("#wines-part1").innerHeight() / bodyHeight;
        var winesMastPart2Height = $("#listes-vins").innerHeight() / bodyHeight;
        var winesMastPart3Height = $("#wines-part3").innerHeight() / bodyHeight;

        var winesWrapperScrollHeight = winesMastHeight + winesMastPart1Height + winesMastPart2Height + winesMastPart3Height;

        var contactMastHeight = $("#contact-mast").innerHeight() / bodyHeight;

         // wines to contact
         this.timeline.to('#wines', 1, { display: 'block', clearProps: 'display', ease: ease, force3D: force3D }, currentTime);
         this.timeline.set('#contact',  { clearProps: 'display' }, currentTime);
         this.timeline.to('#contact', 1, { display: 'block', ease: ease, force3D: force3D }, currentTime);

        // console.log(bodyHeight * (contactMastHeight), contactMastHeight, $("#contact-mast").innerHeight(), bodyHeight * (winesWrapperScrollHeight));

        this.timeline.fromTo('#wines-wrapper', 1 , { y: 0 }, { y: -bodyHeight * (winesWrapperScrollHeight), ease: ease, force3D: force3D }, currentTime);
        this.timeline.fromTo('#contact-wrapper', 1 , { y: bodyHeight * (winesWrapperScrollHeight)}, { y: 0, ease: ease, force3D: force3D }, currentTime);
      
        // Animation

        this.timeline.call(this.animations.sequences.wines.mastEnterAnimation, null, this.animations.sequences.wines, currentTime - .1);
        this.timeline.fromTo('#wines .a2 .pic', .75, {y:200}, {y:-200, ease: ease, force3D: force3D}, currentTime);
        this.timeline.fromTo('#wines .a2 .content .resume', .5, {y:300}, {y:-300, ease: ease, force3D: force3D}, currentTime);
        this.timeline.call(this.animations.sequences.wines.secondPartEnterAnimation, null, this.animations.sequences.wines, currentTime + .2);
        this.timeline.fromTo('#wines .a3 .pic img', .5, {y:150}, {y:-150, ease: ease, force3D: force3D}, currentTime + .35);
        this.timeline.call(this.animations.sequences.wines.thirdPartEnterAnimation, null, this.animations.sequences.wines, currentTime + .35);
        this.timeline.fromTo('#wines .a3 .contentListe', .5, {y:300}, {y:-300, ease: ease, force3D: force3D}, currentTime + .25);
        this.timeline.fromTo('#wines .a4 .content', .5, {y:300}, {y:-300, ease: ease, force3D: force3D}, currentTime + .5);


        currentTime += 1;
        this.keyframes.contact = currentTime;


        // Scroll speeds
        //this.timeline.fromTo(this.animations, 0.5, { scrollSpeed: slowScrollSpeed }, { scrollSpeed: referenceScrollSpeed, ease: ease, force3D: force3D }, currentTime);

      //   // Intro
      //   var introPart1Height =  $('#intro-part1').innerHeight() / bodyHeight;
      //   var introPart2Height =  $('#intro-part2').innerHeight() / bodyHeight;

      //   var introWrapperScrollHeight =  introPart1Height + introPart2Height - 1;


      //   this.timeline.fromTo('#home', 1, { display: 'block' }, { display: 'block', clearProps: 'display,zIndex', ease: ease, force3D: force3D }, currentTime);
      //   this.timeline.set('#intro',  { clearProps: 'display' }, currentTime);
      //   this.timeline.to('#intro', 1, { display: 'block', ease: ease, force3D: force3D }, currentTime);
        
      //   this.timeline.fromTo('#intro-wrapper', 1 , { y: bodyHeight }, { y: 0, ease: ease, force3D: force3D }, currentTime);
      //   this.timeline.fromTo('#home-wrapper', 1 , { y: 0, opacity: 1 }, { y: -150, opacity:0, ease: ease, force3D: force3D }, currentTime);

      //   // Animations
      //   this.timeline.call(this.animations.sequences.intro.mastEnterAnimation, null, this.animations.sequences.intro, currentTime + .75);
      //   this.timeline.fromTo("#intro-part1 .imgAcroche", 1, {y: 50}, {y: -50}, currentTime + .9);
      //   this.timeline.call(this.animations.sequences.intro.secondPartEnterAnimation, null, this.animations.sequences.intro, currentTime + introPart1Height + .5);

      //   currentTime += 1;
      //   this.keyframes.intro = currentTime;
        
  
      //   this.timeline.fromTo('#intro-wrapper', introWrapperScrollHeight, { y: 0 }, { y: -bodyHeight * introWrapperScrollHeight, ease: ease, force3D: force3D }, currentTime);

      //   currentTime += introWrapperScrollHeight;


      //   // Intro to Estate
      //   this.timeline.to('#intro', 1, { display: 'block', clearProps: 'display', ease: ease, force3D: force3D }, currentTime);
      //   this.timeline.set('#estate',  { clearProps: 'display' }, currentTime);
      //   this.timeline.to('#estate', 1, { display: 'block', ease: ease, force3D: force3D }, currentTime);


      //   this.timeline.fromTo('#intro-wrapper', 1, { y: -bodyHeight * introWrapperScrollHeight }, { y: -bodyHeight * (introWrapperScrollHeight + 1), ease: ease, force3D: force3D }, currentTime);
      //   this.timeline.fromTo('#estate-wrapper', 1, { y: bodyHeight }, { y: 0, ease: ease, force3D: force3D }, currentTime);

      //    // Scroll speeds
      //    this.timeline.fromTo(this.animations, 0.25, { scrollSpeed: slowScrollSpeed }, { scrollSpeed: referenceScrollSpeed, ease: ease, force3D: force3D }, currentTime);


      //   currentTime += 1;
      //   this.keyframes.estate = currentTime + .3;

      //   //Estate
      //   var estatePart1Height =  $('#estate-part1').innerHeight() / bodyHeight;
      //   var estatePart2Height =  $('#estate-part2').innerHeight() / bodyHeight;
      //   var estatePart3Height =  $('#estate-part3').innerHeight() / bodyHeight;

      //   var estateWrapperScrollHeight =  estatePart1Height + estatePart2Height + estatePart3Height - 1;

      //   this.timeline.fromTo('#estate-wrapper', estateWrapperScrollHeight, { y: 0 }, { y: -bodyHeight * estateWrapperScrollHeight, ease: ease, force3D: force3D }, currentTime);

      //   //Animations

      //   var bannerPos = bodyHeight*1.44;

      //   this.timeline.fromTo("#estate-part1 .banner", 1, {y: bannerPos}, {y:0}, currentTime - .75);
      //   this.timeline.fromTo("#estate-part1 .bg", 1, {y: (300)}, {y:0}, currentTime - 1);
      //   this.timeline.fromTo("#estate-part1 .acrocheImg", 1, {y: 50}, {y: -50}, currentTime +.25);
      //   this.timeline.call(this.animations.sequences.estate.mastEnterAnimation, null, this.animations.sequences.estate, currentTime + .25);


      //   this.timeline.call(this.animations.sequences.estate.firstPartEnterAnimation, null, this.animations.sequences.estate, currentTime + estatePart1Height - .5);
      //   this.timeline.fromTo("#estate-part2 .first.image-wrapper, #estate-part2 .eye", 3, { y: .5 * bodyHeight }, { y: -.25 * bodyHeight, ease: ease }, currentTime + estatePart1Height - .75);
      //   this.timeline.fromTo("#estate-part2 .seconde img", 3, { top: -100 }, { top: 100 }, currentTime + estatePart1Height - .2);
      //   this.timeline.call(this.animations.sequences.estate.secondPartEnterAnimation, null, this.animations.sequences.estate, currentTime + estatePart1Height + estatePart2Height - 1.1);
      //   this.timeline.call(this.animations.sequences.estate.thirdPartEnterAnimation, null, this.animations.sequences.estate, currentTime + estatePart1Height + estatePart2Height - .75);
      //   this.timeline.fromTo("#estate-part3 .resume .pic", 2, { y: 50 }, { y: -25 }, currentTime + estatePart1Height + estatePart2Height - .25);

      //   currentTime += estateWrapperScrollHeight;


      //   // Estate to History
      //   this.timeline.to('#estate', 1, { display: 'block', clearProps: 'display', ease: ease, force3D: force3D }, currentTime);
      //   this.timeline.set('#history',  { clearProps: 'display' }, currentTime);
      //   this.timeline.to('#history', 1, { display: 'block', ease: ease, force3D: force3D }, currentTime);

      //   this.timeline.fromTo('#estate-wrapper', 1, { y: -bodyHeight * estateWrapperScrollHeight }, { y: -bodyHeight * (estateWrapperScrollHeight + 1), ease: ease, force3D: force3D }, currentTime);
      //   this.timeline.fromTo('#history-wrapper', 1, { y: bodyHeight }, { y: 0, ease: ease, force3D: force3D }, currentTime);

      //   currentTime += 1;
      //   this.keyframes.history = currentTime + .3;


      //   //History
      //   var historyPart1Height =  $('#history-part1').innerHeight() / bodyHeight;
      //   var historyPart2Height =  $('#history-part2').innerHeight() / bodyHeight;
      //   var historyPart3Height =  $('#history-part3').innerHeight() / bodyHeight;

      //   var historyWrapperScrollHeight =  historyPart1Height + historyPart2Height + historyPart3Height - 1;

      //   this.timeline.fromTo('#history-wrapper', historyWrapperScrollHeight, { y: 0 }, { y: -bodyHeight * historyWrapperScrollHeight, ease: ease, force3D: force3D }, currentTime);

      //   //Animation

      //   this.timeline.fromTo("#history-part1 .banner", 1, {y: bannerPos}, {y:0}, currentTime - .75);
      //   this.timeline.fromTo("#history-part1 .bg", 1, {y: (300)}, {y:0}, currentTime - 1);
      //   this.timeline.fromTo("#history-part1 .acrocheImg", 1, {y: 50}, {y: -50}, currentTime +.25);
      //   this.timeline.call(this.animations.sequences.history.mastEnterAnimation, null, this.animations.sequences.history, currentTime);
        
      //   this.timeline.call(this.animations.sequences.history.secondPartEnterAnimation, null, this.animations.sequences.history, currentTime + historyPart1Height - .75);
      //   this.timeline.fromTo("#history-part2 .louis-wrapper img", 2, { top: -50 }, { top: 50 }, currentTime + historyPart1Height - .5);
      //   this.timeline.fromTo("#history-part2 .louis .person", 1.5, { top: -50 }, { top: 50 }, currentTime + historyPart1Height - .5);

      //   this.timeline.fromTo("#history-part2 .emile-wrapper img", 2, { top: -50 }, { top: 50 }, currentTime + historyPart1Height - .25);
      //   this.timeline.fromTo("#history-part2 .emile .person", 1.5, { top: -50 }, { top: 50 }, currentTime + historyPart1Height - .25);
        
      //   this.timeline.fromTo("#history-part2 .phillipe-wrapper img", 2, { top: -50 }, { top: 50 }, currentTime + historyPart1Height);
      //   this.timeline.fromTo("#history-part2 .philippe .person", 1.5, { top: -50 }, { top: 50 }, currentTime + historyPart1Height);

      //   this.timeline.fromTo("#history-part2 .gilles .person", 3, { top: 300 }, { top: -50 }, currentTime + historyPart1Height);

      //   this.timeline.call(this.animations.sequences.history.thirdPartEnterAnimation, null, this.animations.sequences.history, currentTime + historyPart1Height + historyPart2Height - .25);

      //   currentTime += historyWrapperScrollHeight;

      //   // Histrory to Vignard
      //   this.timeline.to('#history', 1, { display: 'block', clearProps: 'display', ease: ease, force3D: force3D }, currentTime);
      //   this.timeline.set('#vignard',  { clearProps: 'display' }, currentTime);
      //   this.timeline.to('#vignard', 1, { display: 'block', ease: ease, force3D: force3D }, currentTime);

      //   this.timeline.fromTo('#history-wrapper', 1, { y: -bodyHeight * historyWrapperScrollHeight }, { y: -bodyHeight * (historyWrapperScrollHeight + 1), ease: ease, force3D: force3D }, currentTime);
      //   this.timeline.fromTo('#vignard-wrapper', 1, { y: bodyHeight }, { y: 0, ease: ease, force3D: force3D }, currentTime);

      //   currentTime += 1;
      //   this.keyframes.vignard = currentTime + .3;
        
      //   //Vignard
      //   var vignardPart1Height =  $('#vignard-part1').innerHeight() / bodyHeight;
      //   var vignardPart2Height =  $('#vignard-part2').innerHeight() / bodyHeight;

      //   var vignardWrapperScrollHeight =  vignardPart1Height + vignardPart2Height - 1;

      //   this.timeline.fromTo('#vignard-wrapper', vignardWrapperScrollHeight, { y: 0 }, { y: -bodyHeight * vignardWrapperScrollHeight, ease: ease, force3D: force3D }, currentTime);

      //   //Animation
      //   this.timeline.fromTo("#vignard-part1 .banner", 1, {y: bannerPos}, {y:0}, currentTime - .75);
      //   this.timeline.fromTo("#vignard-part1 .bg", 1, {y: (300)}, {y:0}, currentTime - 1);
      //   this.timeline.fromTo("#vignard-part1 .acrocheImg", 1, {y: 50}, {y: -50}, currentTime +.25);
      //   this.timeline.call(this.animations.sequences.vignard.mastEnterAnimation, null, this.animations.sequences.vignard, currentTime);
      //   this.timeline.call(this.animations.sequences.vignard.secondPartEnterAnimation, null, this.animations.sequences.vignard, currentTime + vignardPart1Height - .5);



      //   currentTime += vignardWrapperScrollHeight;


      //   //Vignard to Vintages
      //   this.timeline.to('#vignard', 1, { display: 'block', clearProps: 'display', ease: ease, force3D: force3D }, currentTime);
      //   this.timeline.set('#vintages',  { clearProps: 'display' }, currentTime);
      //   this.timeline.to('#vintages', 1, { display: 'block', ease: ease, force3D: force3D }, currentTime);

      //   this.timeline.fromTo('#vignard-wrapper', 1, { y: -bodyHeight * vignardWrapperScrollHeight }, { y: -bodyHeight * (vignardWrapperScrollHeight + 1), ease: ease, force3D: force3D }, currentTime);
      //   this.timeline.fromTo('#vintages-wrapper', 1, { y: bodyHeight }, { y: 0, ease: ease, force3D: force3D }, currentTime);

      //   currentTime += 1;
      //   this.keyframes.vintages = currentTime;


      //   //Vintages
      //   var vintagesWrapperScrollHeight =  ($('#vintages-part1').innerHeight() / bodyHeight) - 1;

      //   this.timeline.fromTo('#vintages-wrapper', vintagesWrapperScrollHeight, { y: 0 }, { y: -bodyHeight * vintagesWrapperScrollHeight, ease: ease, force3D: force3D }, currentTime);

      //   //Animation
      //   this.timeline.call(this.animations.sequences.vintages.mastEnterAnimation, [true], this.animations.sequences.vintages, currentTime - .5);
      //   this.timeline.fromTo("#vintages-part1 .texts .img-wrapper", 1, { y: -50 }, { top: 50 }, currentTime - .5);
      //   this.timeline.fromTo("#vintages-part1 .big-one, #vintages-part1 .eye", 3, { y: .5 * bodyHeight }, { y: -.25 * bodyHeight, ease: ease }, currentTime - 1.5);
      //   // this.timeline.fromTo("#vintages-part1 .small-one", 3, { top: -50 }, { top: 100 }, currentTime);
      //   this.timeline.fromTo("#vintages-part1 .small-one img", 3, { y: 0 }, { y: -100 }, currentTime);
      //   this.timeline.call(this.animations.sequences.vintages.secondPartEnterAnimation, null, this.animations.sequences.vintages, currentTime + vintagesWrapperScrollHeight);
      //   this.timeline.call(this.animations.sequences.vintages.thirdPartEnterAnimation, null, this.animations.sequences.vintages, currentTime + vintagesWrapperScrollHeight + .25);
        
      //   currentTime += vintagesWrapperScrollHeight;

      //   //Vintages to Winemaking
      //   this.timeline.to('#vintages', 1, { display: 'block', clearProps: 'display', ease: ease, force3D: force3D }, currentTime);
      //   this.timeline.set('#winemaking',  { clearProps: 'display' }, currentTime);
      //   this.timeline.to('#winemaking', 1, { display: 'block', ease: ease, force3D: force3D }, currentTime);

      //   this.timeline.fromTo('#vintages-wrapper', 1, { y: -bodyHeight * vintagesWrapperScrollHeight }, { y: -bodyHeight * (vintagesWrapperScrollHeight + 1), ease: ease, force3D: force3D }, currentTime);
      //   this.timeline.fromTo('#winemaking-wrapper', 1, { y: bodyHeight }, { y: 0, ease: ease, force3D: force3D }, currentTime);

      //   currentTime += 1;
      //   this.keyframes.winemaking = currentTime + .1;

      //   //Winemaking
      //   var winemakingPart1Height =  $('#winemaking-part1').innerHeight() / bodyHeight;
      //   var winemakingPart2Height =  $('#winemaking-part2').innerHeight() / bodyHeight;
      //   var winemakingPart3Height =  $('#winemaking-part3').innerHeight() / bodyHeight;

      //   var winemakingWrapperScrollHeight =  winemakingPart1Height + winemakingPart2Height + winemakingPart3Height - 1;

      //   this.timeline.fromTo('#winemaking-wrapper', winemakingWrapperScrollHeight, { y: 0 }, { y: -bodyHeight * winemakingWrapperScrollHeight, ease: ease, force3D: force3D }, currentTime);

      //   //Animation
      //   this.timeline.fromTo("#winemaking-part1 .banner", 1, {y: bannerPos}, {y:0}, currentTime - .75);
      //   this.timeline.fromTo("#winemaking-part1 .bg", 1, {y: (300)}, {y:0}, currentTime - 1);
      //   this.timeline.fromTo("#winemaking-part1 .acrocheImg", 1, {y: 50}, {y: -50}, currentTime +.25);
        
      //   this.timeline.call(this.animations.sequences.winemaking.mastEnterAnimation, null, this.animations.sequences.winemaking, currentTime);
      //   this.timeline.call(this.animations.sequences.winemaking.firstPartEnterAnimation, null, this.animations.sequences.winemaking, currentTime + winemakingPart1Height - .5);
        
      //   this.timeline.fromTo("#winemaking-part2 .photo img", 1, {y:0}, {y:-100}, currentTime + winemakingPart1Height - .5);
      //   this.timeline.fromTo("#winemaking-part3 .back1-wrapper", 1.5, { y: .20 * bodyHeight }, { y: -.20 * bodyHeight, ease: ease }, currentTime + winemakingPart1Height);
      //   this.timeline.fromTo("#winemaking-part3 .back1-wrapper img", 3, {y:100}, {y:-100}, currentTime + winemakingPart1Height - .75);
      //   this.timeline.fromTo("#winemaking-part3 .video-wrapper, #winemaking-part3 .cuillere .texts", 1.25, { y: .25 * bodyHeight }, { y: -.5 * bodyHeight, ease: ease }, currentTime + winemakingPart1Height + winemakingPart2Height  - .5);
    
      //   // this.timeline.fromTo("#winemaking-part3 .cuillere .texts", 1, { y:0}, { y: -200, ease: ease }, currentTime + winemakingPart1Height + winemakingPart2Height + winemakingPart3Height - 1.35);
      //   this.timeline.call(this.animations.sequences.winemaking.secondPartEnterAnimation, null, this.animations.sequences.winemaking, currentTime + winemakingPart1Height + winemakingPart2Height + winemakingPart3Height - 1.35);
    


      //   currentTime += winemakingWrapperScrollHeight;

      //   //Winmaking to Wines
      //   this.timeline.to('#winemaking', 1, { display: 'block', clearProps: 'display', ease: ease, force3D: force3D }, currentTime);
      //   this.timeline.set('#wines',  { clearProps: 'display' }, currentTime);
      //   this.timeline.to('#wines', 1, { display: 'block', ease: ease, force3D: force3D }, currentTime);

      //   this.timeline.fromTo('#winemaking-wrapper', 1, { y: -bodyHeight * winemakingWrapperScrollHeight }, { y: -bodyHeight * (winemakingWrapperScrollHeight + 1), ease: ease, force3D: force3D }, currentTime);
      //   this.timeline.fromTo('#wines-wrapper', 1, { y: bodyHeight }, { y: 0, ease: ease, force3D: force3D }, currentTime);

      //   currentTime += 1;
      //   this.keyframes.wines = currentTime + .3;

      //   //Wines
      //   var winesPart1Height =  $('#wines-part1').innerHeight() / bodyHeight;
      //   var winesPart2Height =  $('#wines-part2').innerHeight() / bodyHeight;
      //   var winesPart3Height =  $('#wines-part3').innerHeight() / bodyHeight;

      //   var winesWrapperScrollHeight =  winesPart1Height + winesPart2Height + winesPart3Height - 1;

      //   this.timeline.fromTo('#wines-wrapper', winesWrapperScrollHeight, { y: 0 }, { y: -bodyHeight * winesWrapperScrollHeight, ease: ease, force3D: force3D }, currentTime);

      //   //Animation
      //   this.timeline.fromTo("#wines-part1 .banner", 1, {y: bannerPos}, {y:0}, currentTime - .75);
      //   this.timeline.fromTo("#wines-part1 .bg", 1, {y: (300)}, {y:0}, currentTime - 1);
      //   this.timeline.fromTo("#wines-part1 .acrocheImg", 1, {y: 50}, {y: -50}, currentTime +.25);
        
      //   this.timeline.call(this.animations.sequences.wines.mastEnterAnimation, [true], this.animations.sequences.wines, currentTime);
      //   this.timeline.call(this.animations.sequences.wines.firstPartEnterAnimation, null, this.animations.sequences.wines, currentTime + winesPart1Height - .5);
      //   this.timeline.call(this.animations.sequences.wines.secondPartEnterAnimation, null, this.animations.sequences.wines, currentTime + winesPart1Height + winesPart2Height - .5);

      //   currentTime += winesWrapperScrollHeight;

      //   //Wines to Distributor
      //   this.timeline.to('#wines', 1, { display: 'block', clearProps: 'display', ease: ease, force3D: force3D }, currentTime);
      //   this.timeline.set('#distributor',  { clearProps: 'display' }, currentTime);
      //   this.timeline.to('#distributor', 1, { display: 'block', ease: ease, force3D: force3D }, currentTime);

      //   this.timeline.fromTo('#wines-wrapper', 1, { y: -bodyHeight * winesWrapperScrollHeight }, { y: -bodyHeight * (winesWrapperScrollHeight + 1), ease: ease, force3D: force3D }, currentTime);
      //   this.timeline.fromTo('#distributor-wrapper', 1, { y: bodyHeight }, { y: 0, ease: ease, force3D: force3D }, currentTime);

      //   currentTime += 1;
      //   this.keyframes.distributor = currentTime + .3;

      //   //Distributor
      //   var distributorPart1Height =  $('#distributor-part1').innerHeight() / bodyHeight;
      //   var distributorPart2Height =  $('#distributor-part2').innerHeight() / bodyHeight;

      //   var distributorWrapperScrollHeight =  distributorPart1Height + distributorPart2Height - 1;

      //   this.timeline.fromTo('#distributor-wrapper', distributorWrapperScrollHeight, { y: 0 }, { y: -bodyHeight * distributorWrapperScrollHeight, ease: ease, force3D: force3D }, currentTime);

      //   //Animation
      //   this.timeline.fromTo("#distributor-part1 .banner", 1, {y: bannerPos}, {y:0}, currentTime - .75);
      //   this.timeline.fromTo("#distributor-part1 .bg", 1, {y: (300)}, {y:0}, currentTime - 1);
      //   this.timeline.fromTo("#distributor-part1 .acrocheImg", 1, {y: 50}, {y: -50}, currentTime +.25);

      //   this.timeline.call(this.animations.sequences.distributor.mastEnterAnimation, [true], this.animations.sequences.distributor, currentTime);
      //   this.timeline.fromTo("#distributor-part2 .gallery .bg-wrapper img", 3.5, {y: 0}, {y: -200}, currentTime + distributorPart1Height - .85);
      //   this.timeline.call(this.animations.sequences.distributor.secondPartEnterAnimation, null, this.animations.sequences.distributor, currentTime + distributorPart1Height - .5);
      //   this.timeline.fromTo("#distributor-part2 .gallery .pic-wrapper", 2,  { y: .25 * bodyHeight }, { y: -.35 * bodyHeight, ease: ease }, currentTime + distributorPart1Height - .25);
      //   currentTime += distributorWrapperScrollHeight;

      //   //Distributor to Contact
      //   this.timeline.to('#distributor', 1, { display: 'block', clearProps: 'display', ease: ease, force3D: force3D }, currentTime);
      //   this.timeline.set('#contact',  { clearProps: 'display' }, currentTime);
      //   this.timeline.to('#contact', 1, { display: 'block', ease: ease, force3D: force3D }, currentTime);

      //   this.timeline.fromTo('#distributor-wrapper', 1, { y: -bodyHeight * distributorWrapperScrollHeight }, { y: -bodyHeight * (distributorWrapperScrollHeight + 1), ease: ease, force3D: force3D }, currentTime);
      //   this.timeline.fromTo('#contact-wrapper', 1, { y: bodyHeight }, { y: 0, ease: ease, force3D: force3D }, currentTime);

      //   currentTime += 1;
      //   this.keyframes.contact = currentTime + .3;

      //   //Contact
      //   var contactPart1Height =  $('#contact-part1').innerHeight() / bodyHeight;
      //   var contactPart2Height =  $('#contact-part2').innerHeight() / bodyHeight;

      //   var contactWrapperScrollHeight =  contactPart1Height + contactPart2Height - 1;

      //   this.timeline.fromTo('#contact-wrapper', contactWrapperScrollHeight, { y: 0 }, { y: -bodyHeight * contactWrapperScrollHeight, ease: ease, force3D: force3D }, currentTime);

      //   //Animation
      //   this.timeline.fromTo("#contact-part1 .banner", 1, {y: bannerPos}, {y:0}, currentTime - .75);
      //   this.timeline.fromTo("#contact-part1 .bg", 1, {y: (300)}, {y:0}, currentTime - 1);
      //   this.timeline.fromTo("#contact-part1 .acrocheImg", 1, {y: 50}, {y: -50}, currentTime +.25);

      //   this.timeline.call(this.animations.sequences.contact.mastEnterAnimation, null, this.animations.sequences.contact, currentTime);
      //   this.timeline.call(this.animations.sequences.contact.secondPartEnterAnimation, null, this.animations.sequences.contact, currentTime + contactPart1Height - .5);
      //   this.timeline.call(this.animations.sequences.contact.thirdPartEnterAnimation, null, this.animations.sequences.contact, currentTime + contactPart1Height - .25);

      //   currentTime += contactWrapperScrollHeight;


      //   // Vine
      //   // var vineMastWidth = $('#vigne-mast').innerWidth() / bodyWidth;
      //   // var vinePart1Width = $('#vigne-part1').innerWidth() / bodyWidth;
      //   // var vinePart2Width = $('#vigne-part2').innerWidth() / bodyWidth;
      //   // var vinePart3Width = $('#vigne-part3').innerWidth() / bodyWidth;
      //   // var vinePart4Width = $('#vigne-part4').innerWidth() / bodyWidth;
        
      //   // var vineWrapperScrollWidth =  vineMastWidth + vinePart1Width + vinePart2Width + vinePart3Width + vinePart4Width - 1;

      //   // var vineWrapperScrollWidthPart1 = vineMastWidth + vinePart1Width - 1;
      //   // var vineWrapperScrollWidthVintages = vineMastWidth + vinePart1Width + vinePart2Width - 1;
      //   // var vineWrapperScrollWidthPart2 = vineMastWidth + vinePart1Width + vinePart2Width + vinePart3Width + vinePart4Width - 1;

        
      //   // Scrolling and paralax effect
      //   // this.timeline.fromTo('#vigne-part1 .article.a2 .ronded', 1, { x: 100 }, { x: -40, ease: ease, force3D: force3D }, currentTime + vineMastWidth - coefTrigger);
      //   // this.timeline.fromTo('#vigne-part1 .article.a2 .ronded .ttr', 1, { x: 50 }, { x: -60, ease: ease, force3D: force3D }, currentTime + vineMastWidth - coefTrigger);
      //   // this.timeline.fromTo('#vigne-part1 .article.a2 .resume .content', 1, { x: 60 }, { x: -30, ease: ease, force3D: force3D }, currentTime + vineMastWidth - coefTrigger);
      //   // this.timeline.fromTo('#vigne-part1 .article.a2 .pic.flex', 1, { x: 100 }, { x: -40, ease: ease, force3D: force3D }, currentTime + vineMastWidth - coefTrigger);
      //   // this.timeline.fromTo('#vigne-part2 .article.a2 .resume', 1, { x: 100 }, { x: -40, ease: ease, force3D: force3D }, currentTime + vineMastWidth - coefTrigger);
      //   // this.timeline.fromTo('#vigne-part2 .article.a3 .bgDate img', 1, { x: 50, scale: 1 }, { x: 0, scale: 1.3, ease: ease, force3D: force3D }, currentTime + vinePart1Width);
      //   // this.timeline.fromTo('#vigne-part2 .article.a3 .date .decouvrir', 1, { x: 110 }, { x: 10, ease: ease, force3D: force3D }, currentTime + vinePart1Width);
      //   // this.timeline.fromTo('#vigne-part3 .article.a2 .ronded', 1, { x: 100 }, { x: -40, ease: ease, force3D: force3D }, currentTime + vineWrapperScrollWidthVintages);
      //   // this.timeline.fromTo('#vigne-part3 .article.a2 .ronded .ttr', 1, { x: 40 }, { x: -60, ease: ease, force3D: force3D }, currentTime + vineWrapperScrollWidthVintages);
      //   // this.timeline.fromTo('#vigne-part3 .article.a2 .resume .content', 1, { x: 110 }, { x: -60, ease: ease, force3D: force3D }, currentTime + vineWrapperScrollWidthVintages);
      //   // this.timeline.fromTo('#vigne-part3 .article.a2 .pic.flex', 1, { x: 75 }, { x: -65, ease: ease, force3D: force3D }, currentTime + vineWrapperScrollWidthPart2 - vinePart4Width);
      //   // this.timeline.fromTo('#vigne-part4 .article.a2 .resume', 1, { x: 75 }, { x: -65, ease: ease, force3D: force3D }, currentTime + vineWrapperScrollWidthPart2 - vinePart4Width);
      //   // this.timeline.fromTo('#vigne-wrapper', vineWrapperScrollWidthPart1, { x: 0 }, { x: -bodyWidth * vineWrapperScrollWidthPart1, ease: ease, force3D: force3D }, currentTime);
        
      //   // Animations
      //  // this.timeline.call(this.animations.sequences.vine.mastEnterAnimation, null, this.animations.sequences.vine, currentTime - .6);


      //   // Animation cercle Spring
        

      //   //this.timeline.call(this.animations.sequences.vine.firstPartEnterAnimation, null, this.animations.sequences.vine, currentTime + vineMastWidth - .1);


      //   //currentTime += vineWrapperScrollWidthPart1;
      //   //this.keyframes.vintages = currentTime + vinePart1Width;


      //   // this.timeline.fromTo('#vigne-wrapper', vineWrapperScrollWidthVintages - vineWrapperScrollWidthPart1 , { x: -bodyWidth * vineWrapperScrollWidthPart1 }, { x: -bodyWidth * vineWrapperScrollWidthVintages, ease: ease, force3D: force3D }, currentTime);


      //   // this.timeline.call(this.animations.sequences.vine.secondPartEnterAnimation, null, this.animations.sequences.vine, currentTime + vinePart1Width - .6);

      //   // currentTime += vineWrapperScrollWidthVintages - vineWrapperScrollWidthPart1;

      //   // this.timeline.call(this.animations.sequences.vine.thirdPartEnterAnimation, null, this.animations.sequences.vine, currentTime + vinePart3Width - .1);
      //   // this.timeline.call(this.animations.sequences.vine.galleryPartEnterAnimation, null, this.animations.sequences.vine, currentTime + vinePart2Width - .4);

      //   // Animation cercle Summer
      //   // this.timeline.fromTo(arcDataSummer, vinePart1Width * (.3 + coefTrigger), { 'angle': angleDegreStart}, { 'angle': angleDegreStop }, currentTime);
               
      //   //  pixiAppSummer.ticker.add(function() {
      //   //     arcSummer.clear(); 
      //   //     arcSummer.lineStyle(48, 0xcbca45, 1)
      //   //     arcSummer.arc(arcDataSummer.x, arcDataSummer.y, arcDataSummer.radius, startDrawCircle, _.round(arcDataSummer.angle * RAD + .01, 2));
      //   //  }, PIXI.UPDATE_PRIORITY.NORMAL);

        
      //   // this.timeline.fromTo('#vigne-wrapper', vineWrapperScrollWidthPart2 - vineWrapperScrollWidthVintages , { x: -bodyWidth * vineWrapperScrollWidthVintages }, { x: -bodyWidth * vineWrapperScrollWidthPart2, ease: ease, force3D: force3D }, currentTime);

      //   // this.keyframes.vinepart2 = currentTime + (vineWrapperScrollWidthPart2 - vineWrapperScrollWidthVintages - vineWrapperScrollWidthPart1);
      //   // currentTime += vineWrapperScrollWidthPart2 - vineWrapperScrollWidthVintages;

        
        
        
      //   // vine to Winemaking
      //   // this.timeline.to('#vigne', 1, { display: 'block', clearProps: 'display', ease: ease, force3D: force3D }, currentTime);
      //   // this.timeline.set('#vinification', { clearProps: 'display' }, currentTime);
      //   // this.timeline.to('#vinification', 1, { display: 'block', ease: ease, force3D: force3D}, currentTime);
        
      //   // this.timeline.fromTo('#vigne-wrapper', slowPartCoef, { x: -bodyWidth * vineWrapperScrollWidth }, { x: -bodyWidth * (vineWrapperScrollWidth + 1), ease: ease, force3D: force3D }, currentTime);
      //   // this.timeline.fromTo('#vinification .bg-blue .article', 1.2, { x: 100 }, { x: -40, ease: ease, force3D: force3D }, currentTime);
      //   // this.timeline.fromTo('#vinification .bg-blue .intro img', 1, { x: 0 }, { x: -100, ease: ease, force3D: force3D }, currentTime);
      //   // this.timeline.fromTo('#vinification .bg-blue .intro', .6, { height: 0, opacity: 0 }, { height: domaineIntroHeight, opacity: 1, ease: ease, force3D: force3D }, currentTime + .15);
      //   // this.timeline.fromTo('#vinification-wrapper', 1, { x: bodyWidth }, { x: 0, ease: ease, force3D: force3D }, currentTime);

      //   //  currentTime += 1;
      //   //  this.keyframes.winemaking = currentTime;
        
      //   // Winemaking
      //   // var winemakingMastWidth = $('#vinification-mast').innerWidth() / bodyWidth;
      //   // var winemakingPart1Width = $('#vinification-part1').innerWidth() / bodyWidth;
      //   // var winemakingPart2Width = $('#vinification-part2').innerWidth() / bodyWidth;
      //   // var winemakingWrapperScrollWidth = winemakingMastWidth + winemakingPart1Width + winemakingPart2Width - 1;



      //   // Scrolling and paralax effect
      //   // this.timeline.fromTo('#vinification-part1 .article.a2 .ronded', 1.1, { x: 100 }, { x: -40, ease: ease, force3D: force3D }, currentTime + .3);
      //   // this.timeline.fromTo('#vinification-part1 .article.a2 .ronded .ttr', 1.1, { x: 60 }, { x: -60, ease: ease, force3D: force3D }, currentTime + .3);
      //   // this.timeline.fromTo('#vinification-part1 .article.a2 .resume .content', 1.1, { x: 60 }, { x: -40, ease: ease, force3D: force3D }, currentTime + .3);
      //   // this.timeline.fromTo('#vinification-part1 .article.a2 .pic.flex', 1.1, { x: 70 }, { x: -70, ease: ease, force3D: force3D }, currentTime + winemakingMastWidth);
      //   // this.timeline.fromTo('#vinification-part2 .article.a2 .resume', 1.1, { x: 70 }, { x: -70, ease: ease, force3D: force3D }, currentTime + winemakingMastWidth);
      //   // this.timeline.fromTo('#vinification-part2 .article.a3 .galerie', 1.1, { x: -90 }, { x: -110, ease: ease, force3D: force3D }, currentTime + winemakingMastWidth + winemakingPart1Width - .3);
      //   // this.timeline.fromTo('#vinification-part2 .article.a3 .galerie .pic2', 1.1, { x: 70 }, { x: 0, ease: ease, force3D: force3D }, currentTime + winemakingMastWidth + winemakingPart1Width - .35);
      //   // this.timeline.fromTo('#vinification-wrapper', winemakingWrapperScrollWidth, { x: 0 }, { x: -bodyWidth * winemakingWrapperScrollWidth, ease: ease, force3D: force3D }, currentTime);


      //   // Animation cercle Autumn
      //   // this.timeline.fromTo(arcDataAutumn, winemakingPart1Width * (.3 + coefTrigger), { 'angle': angleDegreStart}, { 'angle': angleDegreStop }, currentTime + winemakingMastWidth - coefTrigger);
                      
      //   // pixiAppAutumn.ticker.add(function() {
      //   //   arcAutumn.clear(); 
      //   //   arcAutumn.lineStyle(48, 0xa64d3a, 1)
      //   //   arcAutumn.arc(arcDataAutumn.x, arcDataAutumn.y, arcDataAutumn.radius, startDrawCircle, _.round(arcDataAutumn.angle * RAD + .01, 2));
      //   // }, PIXI.UPDATE_PRIORITY.NORMAL);

       
      //   // Animations
      //   // this.timeline.call(this.animations.sequences.winemaking.mastEnterAnimation, null, this.animations.sequences.winemaking, currentTime - .6);
        
      //   // currentTime += winemakingPart1Width;

      //   // this.timeline.call(this.animations.sequences.winemaking.firstPartEnterAnimation, null, this.animations.sequences.winemaking, currentTime - .35);

      //   // currentTime += winemakingPart2Width;

      //   // this.timeline.call(this.animations.sequences.winemaking.secondPartEnterAnimation, null, this.animations.sequences.winemaking, currentTime - .6);
        
      //   // currentTime += winemakingWrapperScrollWidth - winemakingPart1Width - winemakingPart2Width;

      
      //   // Winemaking to wines
      //   // this.timeline.to('#vinification', 1, { display: 'block', clearProps: 'display', ease: ease, force3D: force3D }, currentTime);
      //   // this.timeline.set('#nosVins', { clearProps: 'display' }, currentTime);
      //   // this.timeline.to('#nosVins', 1, { display: 'block', ease: ease, force3D: force3D }, currentTime);
        
      //   // this.timeline.fromTo('#vinification-wrapper', 1.25, { x: -bodyWidth * winemakingWrapperScrollWidth }, { x: -bodyWidth * (winemakingWrapperScrollWidth + 1), ease: ease, force3D: force3D }, currentTime);
      //   // this.timeline.fromTo('#nosVins .bg-blue .article', 1.2, { x: 100 }, { x: -40, ease: ease, force3D: force3D }, currentTime);
      //   // this.timeline.fromTo('#nosVins .bg-blue .intro img', 1, { x: 0 }, { x: -100, ease: ease, force3D: force3D }, currentTime);
      //   // this.timeline.fromTo('#nosVins .bg-blue .intro', .6, { height: 0, opacity: 0 }, { height: domaineIntroHeight, opacity: 1, ease: ease, force3D: force3D }, currentTime + .15);
      //   // this.timeline.fromTo('#nosVins-wrapper', 1, { x: bodyWidth }, { x: 0, ease: ease, force3D: force3D }, currentTime);

      //   // Scroll speeds
        

        
      //   // currentTime += 1;
      //   // this.keyframes.wines = currentTime;

      //    // Wines
      //   //  var winesMastWidth = $('#nosVins-mast').innerWidth() / bodyWidth;
      //   //  var winesPart1Width = $('#nosVins-part1').innerWidth() / bodyWidth;
      //   //  var winesPart2Width = $('#nosVins-part2').innerWidth() / bodyWidth;
      //   //  var winesWrapperScrollWidth = winesMastWidth + winesPart1Width + winesPart2Width - 1;
         

      //    // Scrolling and paralax effect
      //   // this.timeline.fromTo('#nosVins-part1 .article.a2 .ronded', 1.1, { x: 100 }, { x: -40, ease: ease, force3D: force3D }, currentTime + .3);
      //   // this.timeline.fromTo('#nosVins-part1 .article.a2 .ronded .ttr', 1.1, { x: 150 }, { x: 20, ease: ease, force3D: force3D }, currentTime + .3);
      //   // this.timeline.fromTo('#nosVins-part1 .article.a2 .resume .content', 1.1, { x: 80 }, { x: -40, ease: ease, force3D: force3D }, currentTime + .3);
      //   // this.timeline.fromTo('#nosVins-part1 .article.a2 .pic.flex', 1.1, { x: 70 }, { x: -70, ease: ease, force3D: force3D }, currentTime + winesMastWidth);
      //   // this.timeline.fromTo('#nosVins-part2 .article.a2 .resume', 1.1, { x: 70 }, { x: -70, ease: ease, force3D: force3D }, currentTime + winesMastWidth);
      //   // this.timeline.fromTo('#nosVins-part2 .map .pic1 img', 1.1, { x: -200 }, { x: 0, ease: ease, force3D: force3D }, currentTime + winesMastWidth + .3);
      //   // this.timeline.fromTo('#nosVins-wrapper', winesWrapperScrollWidth, { x: 0 }, { x: -bodyWidth * winesWrapperScrollWidth, ease: ease, force3D: force3D }, currentTime);


       
         
      //    // Animations
      //   //  this.timeline.call(this.animations.sequences.wines.mastEnterAnimation, null, this.animations.sequences.wines, currentTime - .6);

      //   //  currentTime += winesMastWidth;

      //   //  this.timeline.call(this.animations.sequences.wines.firstPartEnterAnimation, null, this.animations.sequences.wines, currentTime - .15);

      //   //  currentTime += winesPart1Width;

      //   //  this.timeline.call(this.animations.sequences.wines.secondPartEnterAnimation, null, this.animations.sequences.wines, currentTime - .45);

      //   //  currentTime += winesWrapperScrollWidth - winesPart1Width - winesMastWidth;

      //    //wines to contact
      //   //  this.timeline.to('#nosVins', 1, { display: 'block', clearProps: 'display', ease: ease, force3D: force3D }, currentTime);
      //   // this.timeline.set('#contact', { clearProps: 'display' }, currentTime);
      //   // this.timeline.to('#contact', 1, { display: 'block', ease: ease, force3D: force3D }, currentTime);
        

      //   // this.timeline.fromTo('#nosVins-wrapper', 1, { x: -bodyWidth * winesWrapperScrollWidth }, { x: -bodyWidth * (winesWrapperScrollWidth + 1), ease: ease, force3D: force3D }, currentTime);
      //   // this.timeline.fromTo('#contact .bg-blue .article', 1.2, { x: 100 }, { x: -40, ease: ease, force3D: force3D }, currentTime);
      //   // this.timeline.fromTo('#contact .bg-blue .intro img', 1, { x: 0 }, { x: -100, ease: ease, force3D: force3D }, currentTime);
      //   // this.timeline.fromTo('#contact .bg-blue .intro', .6, { height: 0, opacity: 0 }, { height: domaineIntroHeight, opacity: 1, ease: ease, force3D: force3D }, currentTime + .15);
      //   // this.timeline.fromTo('#contact-wrapper', 1, { x: bodyWidth }, { x: 0, ease: ease, force3D: force3D }, currentTime);
        
      //   // currentTime += 1;
      //   // this.keyframes.contact = currentTime;

      //   // Contact
      //   //  var contactMastWidth = $('#contact-mast').innerWidth() / bodyWidth;
      //   //  var contactPart1Width = $('#contact-part1').innerWidth() / bodyWidth;
      //   //  var contactWrapperScrollWidth = contactMastWidth + contactPart1Width - 1;

      //    // Animations
      //    //this.timeline.call(this.animations.sequences.contact.mastEnterAnimation, null, this.animations.sequences.contact, currentTime - .6);
         
      //    //this.timeline.fromTo('#contact-wrapper', 1, { x: 0 }, { x: -bodyWidth * contactWrapperScrollWidth, ease: ease, force3D: force3D }, currentTime);

      //    //currentTime += contactWrapperScrollWidth;

      //    //currentTime += contactWrapperScrollWidth - contactPart1Width - contactMastWidth;
                
        this.duration = this.timeline.totalDuration();
        this.scrollHeight = currentTime * bodyHeight;

        if (!initialized) {
          this.timeline.progress(previousProgress);
          this.timeline.invalidate();
          initialized = true;
        } else {
          this.timeline.invalidate();
          this.timeline.progress(previousProgress);
        }
      };

      Main.prototype.setTime = function (time, duration, ease) {
        var safeTime = Math.min(Math.max(time, 0), this.duration);

        TweenMax.killTweensOf(this.timeline);
        if (duration === undefined || duration === null || duration === 0) {
          this.timeline.time(safeTime);
        } else {
          TweenMax.to(this.timeline, duration, { time: safeTime, ease: ease });
        }
      }

      return Main;
    })();

    Sequences.Main = Main;
  })(Aneox.Sequences || (Aneox.Sequences = {}));
})(Aneox || (Aneox = {}));