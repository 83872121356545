var Aneox;
(function (Aneox) {
  'use strict';

  var Vintages = (function () {

    var self;

    var isOpen = false;
    var scroll = "";
    var vintagesListElement;
    var vintageElement;
    var currentVintageId;
    var activeSection;
    var baseHref ;
    var data;
    var dataFirstVintage;

    var bodyHeight;

    function Vintages(locale, router) {

      this.locale = locale;
      this.router = router;
    
      self = this;

      vintagesListElement = $("#vintages-list");
      vintageElement = $('#millesimePopup');
      
      baseHref = $('base').attr('href');
      data = self.getData();
      dataFirstVintage = data[data.length - 1];

      bodyHeight = $(window).innerHeight();

    }

    Vintages.prototype.getData = function(){
        return _.orderBy(Aneox.Data.Data.vintages, 'year', 'desc');
    }

    Vintages.prototype.setVintagesList = function(){
      
      vintagesListElement.empty();

      var fragment = $(document.createDocumentFragment());

      _.forEach(data, function(vintage){
          var localeData = _.find(vintage.locales, {locale: self.locale.getLocale()});
          fragment.append('<div class="year" ><a router-href="'+ vintage.year +'">'+ vintage.year +'</a></div>');
      });
      vintagesListElement.append(fragment);

      

      self.router.reparseAnchors();
    }

    Vintages.prototype.show = function(id){
      activeSection = $('section:visible');
      currentVintageId = id;
      setVintageData();

      scroll = $('.millesimePopup .absolute-details');

      try{
        $('.millesimePopup .absolute-details').data("mCS") && scroll.mCustomScrollbar("destroy"); //Destroy
      }catch (e){
        $('.millesimePopup .absolute-details').data("mCS",''); //Manual Destroy

      }

      scroll.mCustomScrollbar({
          setHeight:240,
          theme:"rounded"
      });

      var timeline = new TimelineMax();

      timeline.set(vintageElement.find(".millesimePopup"), {y: bodyHeight, force3D: true, immediateRender:true});
      timeline.set(vintageElement.find(".millesimePopup .back"), {y: bodyHeight, force3D: true, immediateRender:true});
      timeline.set(vintageElement.find(".millesimePopup .texts .content-centered"), {opacity:0, y: 100, force3D: true, immediateRender:true});
      timeline.set(vintageElement.find(".millesimePopup .vintage-sheet"), {opacity:0, y: 50, force3D: true, immediateRender:true});
      timeline.set(vintageElement.find(".millesimePopup .next-vintage"), {opacity:0, x: -50, force3D: true, immediateRender:true});
      timeline.set(vintageElement.find(".millesimePopup .popup-head a"), {opacity:0, force3D: true, immediateRender:true});

      timeline.set(vintageElement, {display: 'block', opacity: 1, force3D: true, immediateRender:true});

      timeline.to(vintageElement.find(".millesimePopup"), .75, {y: 0, ease: Power2.easeOut, force3D: true}, 0);
      timeline.to(vintageElement.find(".millesimePopup .back"), .75, {y: 0, ease: Power2.easeOut, force3D: true}, .25);
      timeline.to(vintageElement.find(".millesimePopup .texts .content-centered"), 1, {opacity:1, y: 0, ease: Power2.easeOut, force3D: true}, .5);
      timeline.to(vintageElement.find(".millesimePopup .next-vintage"), 1, {opacity:1, x: 0, ease: Power2.easeOut, force3D: true}, .75);
      timeline.to(vintageElement.find(".millesimePopup .vintage-sheet"), 1, {opacity:1, y: 0, ease: Power2.easeOut, force3D: true}, .75);
      timeline.to(vintageElement.find(".millesimePopup .popup-head a"), 1, {opacity:1, ease: Power2.easeOut, force3D: true}, .75);
      
      if(!isOpen){
        this.trigger('vintagesWillShow');
      }

      isOpen = true;
    }
    
    Vintages.prototype.swap = function(id){
      activeSection = $('section:visible');
      currentVintageId = id;
      setVintageData();

      scroll = $('.millesimePopup .absolute-details');

      try{
        $('.millesimePopup .absolute-details').data("mCS") && scroll.mCustomScrollbar("destroy"); //Destroy
      }catch (e){
        $('.millesimePopup .absolute-details').data("mCS",''); //Manual Destroy

      }

      scroll.mCustomScrollbar({
          setHeight:240,
          theme:"rounded"
      });
    }
    Vintages.prototype.getLastVintage = function(){
      if(!_.isEmpty(data)){
        return data[0].year;
      }
    }

    Vintages.prototype.getCurrentVintage = function(input){
      return  $(input).val();
    }

 
    Vintages.prototype.handleWindowResize = function(bodyWidth, bodyHeight) {
      
      if(isOpen){
      
      }
      
      if (!isOpen) {
       
      }
    }

    Vintages.prototype.close = function(){
      if(!isOpen) return;
      isOpen = false;

      TweenMax.fromTo(vintageElement, 0.5, { opacity: 1 }, { opacity: 0, display: 'none', onComplete: function () {
        scroll.mCustomScrollbar('destroy');
        vintageElement.css('display', 'none');
        self.trigger('vintagesWillHide');
      } });
    }

    var setVintageData = function(){
      var data  = _.find(Aneox.Data.Data.vintages, {year: currentVintageId});
      var localeData = _.find(data.locales, {locale: self.locale.getLocale()});
      var nextVintage = parseInt(currentVintageId) + 1;

      

      vintageElement.find('.year').html(data.year);

      var dataNext = _.find(Aneox.Data.Data.vintages, {year: ''+nextVintage+''});

      if(dataNext === undefined){
        var nextVintageURL = self.router.getNodePath(dataFirstVintage.year, self.locale.getLocale());
      }else{
        var nextVintageURL = self.router.getNodePath(dataNext.year, self.locale.getLocale());
      }

      vintageElement.find('.details').html(localeData.text1);
      vintageElement.find('.next-vintage').attr('href', nextVintageURL);
      vintageElement.find('.vintage-sheet').attr('href', 'vintage-pdf.php?&lang='+ self.locale.getLocale() +'&vintage='+data.year);
      
    }

    MicroEvent.mixin(Vintages);

    return Vintages;
  })();

  Aneox.Vintages = Vintages;
})(Aneox || (Aneox = {}));
