var Aneox;
(function (Aneox) {
  (function (Sequences) {
    'use strict';

    var Vignard = (function () {

      var self;

      var vignard;
      var vignardVisibility;
      var vignardMast;
      
      var hasPlayedThroughAnimation;

      function Vignard(animations, resources) {
        self = this;
        this.animations = animations;
        this.resources = resources;
        
        hasPlayedThroughAnimation = {};
        vignard = $('#vignard');
        vignardMast = $('#vignard-mast');
        
        var observer = new MutationObserver(function (mutations) {
          mutations.forEach(function (mutation) {
            if (mutation.attributeName === 'style') {
              var display = vignard.css('display');
              var previousVignardVisibility = vignardVisibility;
              vignardVisibility = display !== 'none';
              
              if (!previousVignardVisibility && display !== 'none') {
                self.handleWindowResize();
              }
            }
          });
        });
        observer.observe(vignard[0], { attributes: true });

        this.resetAnimations();
      }
      
      Vignard.prototype.handleWindowResize = function (bodyWidth, bodyHeight) {
        // if (!vineVisibility) return;
      };
      
      Vignard.prototype.resetAnimations = function () {
        hasPlayedThroughAnimation = {};
        //mast
        self.animations.initSectionIntroTimeline($('#vignard'));
       
        //part2
        // $('.frame', vignard).toggleClass('active');
        TweenMax.set('#vignard .a2 .content .pic .forme', {opacity:0, force3D: true, immediateRender: true});

        // part3
        self.animations.initSubtitleTimeline($('#vignard .a3 .ttr'));

        // part4
        $('.frame', vignard).removeClass('active');
       
      };
      
      Vignard.prototype.fastForwardAnimations = function () {
        this.resetAnimations();
        this.mastEnterAnimation(true, 0);
        this.secondPartEnterAnimation(true, 0);
        this.thirdPartEnterAnimation(true, 0);
        this.fourthPartEnterAnimation(true, 0);
      };
      
      Vignard.prototype.mastEnterAnimation = function (force, timescale) {
        if (!force && (!self.animations.enabled || hasPlayedThroughAnimation.mast)) return;
        
        if (timescale === undefined) timescale = 1;

        var timeline = new TimelineMax();
        
        timeline.call(this.animations.SectionIntroTimeline, [$('#vignard')], self.animations,1 * timescale);

        timeline.play();
      

        hasPlayedThroughAnimation.mast = true;
      };

    
      Vignard.prototype.secondPartEnterAnimation = function (force, timescale) {
        if (!force && (!self.animations.enabled || hasPlayedThroughAnimation.second)) return;
        
        if (timescale === undefined) timescale = 1;
        
        var timeline = new TimelineMax();

        timeline.to('#vignard .a2 .content .pic .forme', .5 ,{opacity: 1, force3D: true, delay: 0 * timescale});

        // timeline.call(function(){
        //     $('.frame', estate).toggleClass('active');
        //   }, self.animations, 0 * timescale);
          
        hasPlayedThroughAnimation.second = true;
        };

      Vignard.prototype.thirdPartEnterAnimation = function (force, timescale) {
        if (!force && (!self.animations.enabled || hasPlayedThroughAnimation.third)) return;
        
        if (timescale === undefined) timescale = 1;

        var timeline = new TimelineMax();
        
        timeline.call(this.animations.SubtitleTimeline, [$('#vignard .a3 .ttr')], self.animations,0 * timescale);
        
        hasPlayedThroughAnimation.third = true;
      };

      Vignard.prototype.fourthPartEnterAnimation = function (force, timescale) {
        if (!force && (!self.animations.enabled || hasPlayedThroughAnimation.fourth)) return;
        
        if (timescale === undefined) timescale = 1;

        var timeline = new TimelineMax();

        timeline.call(function(){
            $('.frame', vignard).addClass('active');
          }, self.animations, 0 * timescale);
          
          hasPlayedThroughAnimation.fourth = true;
        };

      Vignard.prototype.galleryPartEnterAnimation = function (force, timescale) {
        if (!force && (!self.animations.enabled || hasPlayedThroughAnimation.gallery)) return;
        
        if (timescale === undefined) timescale = 1;

        var timeline = new TimelineMax();
        
        // timeline.to('#vignard-part4 .article.a2 .resume', 1, { opacity: 1, ease: Power1.easeOut, force3D: true });
        // timeline.to('#vignard-part4 .article.a3 .galerie .g1', .7, { opacity: 1, ease: Power1.easeOut, force3D: true }, "-=.35");
        // timeline.to('#vignard-part4 .article.a3 .galerie .g2', .7, { opacity: 1, ease: Power1.easeOut, force3D: true }, "-=.35");
        // timeline.to('#vignard-part4 .article.a3 .galerie .g3', .7, { opacity: 1, ease: Power1.easeOut, force3D: true }, "-=.35");

        hasPlayedThroughAnimation.gallery = true;
      };

      return Vignard;
    })();

    Sequences.Vignard = Vignard;
  })(Aneox.Sequences || (Aneox.Sequences = {}));
})(Aneox || (Aneox = {}));