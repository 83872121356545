var Aneox;
(function (Aneox) {
  (function (Sequences) {
    'use strict';

    var Philosophy = (function () {

      var self;

      var philosophy;
      var philosophyVisibility;
      var philosophyMast;
      
      var hasPlayedThroughAnimation;

      function Philosophy(animations, resources) {
        self = this;
        this.animations = animations;
        this.resources = resources;
        
        hasPlayedThroughAnimation = {};
        
        philosophy = $('#philosophy');
        philosophyMast = $('#Philosophy-mast');
        
        var observer = new MutationObserver(function (mutations) {
          mutations.forEach(function (mutation) {
            if (mutation.attributeName === 'style') {
              var display = philosophy.css('display');
              var previousPhilosophyVisibility = philosophyVisibility;
              philosophyVisibility = display !== 'none';
              
              if (!previousPhilosophyVisibility && display !== 'none') {
                self.handleWindowResize();
              }
            }
          });
        });
        observer.observe(philosophy[0], { attributes: true });
        
        this.resetAnimations();
      }
      
      Philosophy.prototype.handleWindowResize = function (bodyWidth, bodyHeight) {
        // if (!domaineVisibility) return;
      };
      
      Philosophy.prototype.resetAnimations = function () {
        hasPlayedThroughAnimation = {};
        //mast
        self.animations.initSectionIntroTimeline($('#philosophy'));
  

        //part 2
        $('.frame', philosophy).toggleClass('active');
        // TweenMax.set("#history-part2 .ballStart", {scale:0, force3D: true, immediateRender: true});
        // TweenMax.set("#history-part2 .timeline", {scaleY: 0, force3D: true, immediateRender: true});
        // TweenMax.set("#history-part2 .louis, #history-part2 .emile", {opacity: 0, y: 200, force3D: true, immediateRender: true})
       

        //part 3
        // TweenMax.set('#history-part3 .resume .name, #history-part3 .resume .description', { y: "110%", opacity: 0, force3D: true, immediateRender: true });
        // this.animations.initSubtitleTimeline($('#history-part2 .storyListe .final .p-title'));

      };
      
      Philosophy.prototype.fastForwardAnimations = function () {
        this.resetAnimations();
        this.mastEnterAnimation(true, 0);
        this.secondPartEnterAnimation(true, 0);
        this.thirdPartEnterAnimation(true, 0);
      };
      
      Philosophy.prototype.mastEnterAnimation = function (force, timescale) {
        if (!force && (!self.animations.enabled || hasPlayedThroughAnimation.mast)) return;
        
        if (timescale === undefined) timescale = 1;

        var timeline = new TimelineMax();
        
        timeline.call(this.animations.SectionIntroTimeline, [$('#philosophy')], self.animations, 0 * timescale);

        hasPlayedThroughAnimation.mast = true;
      };


      Philosophy.prototype.secondPartEnterAnimation = function (force, timescale) {
        if (!force && (!self.animations.enabled || hasPlayedThroughAnimation.second)) return;
        
        if (timescale === undefined) timescale = 1;
        
        var timeline = new TimelineMax();
        
        timeline.call(function(){
          $('.frame', philosophy).toggleClass('active');
        }, self.animations, 0 * timescale);

        hasPlayedThroughAnimation.second = true;
      };


      Philosophy.prototype.thirdPartEnterAnimation = function (force, timescale) {
        if (!force && (!self.animations.enabled || hasPlayedThroughAnimation.third)) return;
        
        if (timescale === undefined) timescale = 1;
        
        var timeline = new TimelineMax();
        
        // timeline.call(this.animations.SubtitleTimeline,[$('#history-part2 .storyListe .final .p-title')], this.animations, 0 * timescale);
        // timeline.to("#history-part3 .maskImg", 1.5, {y:720, ease: Power1.easeOut, force3D: true}, 0 * timescale);
        // timeline.staggerTo('#history-part3 .resume .name, #history-part3 .resume .description', 1, { y: "0%", opacity: 1}, .25, -.75 * timescale);

        hasPlayedThroughAnimation.third = true;
      };



      return Philosophy;
    })();

    Sequences.Philosophy = Philosophy;
  })(Aneox.Sequences || (Aneox.Sequences = {}));
})(Aneox || (Aneox = {}));