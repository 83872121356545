var Aneox;
(function (Aneox) {
  'use strict';
  
  // lazysizes - add simple support for background images
  document.addEventListener('lazybeforeunveil', function (e) {
    var bg = e.target.getAttribute('data-bg');
    if (bg) {
      e.target.style.backgroundImage = 'url(' + bg + ')';
    }
  });

  // Attach FastClick
  $(function() {
    FastClick.attach(document.body);
  });




  // Main bootlejs init
  var bottle = new Bottle();
  
  bottle.service('Library.Fonts', Aneox.Library.Fonts);
  bottle.service('Library.Locale', Aneox.Library.Locale);
  bottle.service('Library.Router', Aneox.Library.Router);
  bottle.service('Library.Analytics', Aneox.Library.Analytics, 'Library.Router');
  
  bottle.service('Animations', Aneox.Animations, 'Library.Locale');
  bottle.service('Wines', Aneox.Wines, 'Animations', 'Library.Locale', 'Library.Router');
  bottle.service('MorePages', Aneox.MorePages, 'Library.Locale', 'Library.Router');

  
  bottle.service('App', Aneox.App, 'Library.Fonts', 'Library.Locale', 'Library.Router', 'Library.Analytics', 'Animations', 'Wines', 'MorePages');
  
  window.app = bottle.container.App;

})(Aneox || (Aneox = {}));