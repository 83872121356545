var Aneox;
(function (Aneox) {
  (function (Sequences) {
    'use strict';

    var Home = (function () {

      var self;

      var home;
      var homeVisibility;
      var homeMast;
      var homePart1;
      
      var hasPlayedThroughAnimation;

      function Home(animations, resources) {
        self = this;
        this.animations = animations;
        this.resources = resources;
        hasPlayedThroughAnimation = {};
        
        home = $('#home');
        homeMast = $('#home-mast');
        homePart1 = $('#home-part1');
        
        var observer = new MutationObserver(function (mutations) {
          mutations.forEach(function (mutation) {
            if (mutation.attributeName === 'style') {
              var display = home.css('display');
              var previousHomeVisibility = homeVisibility;
              homeVisibility = display !== 'none';
              
              if (!previousHomeVisibility && display !== 'none') {
                self.handleWindowResize();
              }
            }
          });
        });
        observer.observe(home[0], { attributes: true });
        
        this.resetAnimations();
      }
      
      Home.prototype.handleWindowResize = function (bodyWidth, bodyHeight) {
        // if (!homeVisibility) return;
      };
      
      Home.prototype.resetAnimations = function () {
        hasPlayedThroughAnimation = {};
        

       


        //mast

        TweenMax.set("#home .logo .logoSvg", {scale: 600, force3D: true, immediateRender: true});
        TweenMax.set("#home .lv", {height:"0%", force3D: true, immediateRender: true});
        TweenMax.set(".navFixed", {opacity:0, force3D: true, immediateRender: true});
        // TweenMax.set("#home .bg", {opacity: 0, force3D: true, immediateRender: true});
        // TweenMax.set("#home .logo .illustration", {y: newYpos, force3D: true, immediateRender: true});
        // TweenMax.set("#home .logo .name, #home .acroche", {opacity: 0, scale: 0, force3D: true, immediateRender: true});
        // TweenMax.set("#home .bottom .scroll-down", {opacity: 0, y:50, force3D: true, immediateRender: true});
        // self.animations.initSubtitleTimeline($('#home .bottom'));
        // self.animations.initSubtitleTimeline($('.bandeauTop .lang-wrapper'));
        // self.animations.initSubtitleTimeline($('.bandeauTop .menuBurger-wrapper'));

      };
      
      Home.prototype.fastForwardAnimations = function () {
        this.resetAnimations();
        this.mastEnterAnimation(true, 0);
      };
      
      Home.prototype.mastEnterAnimation = function (force, timescale) {


        if (!force && (!self.animations.enabled || hasPlayedThroughAnimation.mast)) return;
        
        if (timescale === undefined) timescale = 1;
        
        var timeline = new TimelineMax();


        timeline.to("#home .logo .logoSvg",1 , {scale: 1, force3D: true, ease: Power2.inOut}, .5 * timescale)
        timeline.to("#home .lv",1 , {height: "100%", force3D: true, ease: Power2.inOut}, 1.25 * timescale)
        timeline.to(".navFixed",1 , {opacity:1, force3D: true, ease: Power2.inOut}, 1.25 * timescale)
        // timeline.to("#home .bg",1 , {opacity: 1, force3D: true, ease: Power2.inOut}, 1 * timescale)
        // timeline.to("#home .logo .illustration",.5 , {scale: 1, y:-80, force3D: true, ease: Power2.inOut}, 3 * timescale)
        // timeline.to("#home .logo .name, #home .acroche",.75 , {opacity: 1,scale: 1, force3D: true, ease: Power2.inOut}, 3 * timescale)
        // timeline.call(this.animations.SubtitleTimeline, [$('#home .bottom')], self.animations,3.2 * timescale);
        // timeline.to("#home .bottom .scroll-down", .5, {opacity: 1, y:0, force3D: true}, 3.2 * timescale);
        // timeline.call(this.animations.SubtitleTimeline, [$('.bandeauTop .lang-wrapper')], self.animations,3.2 * timescale);
        // timeline.call(this.animations.SubtitleTimeline, [$('.bandeauTop .menuBurger-wrapper')], self.animations,3.2 * timescale);

        hasPlayedThroughAnimation.mast = true;
      };

      return Home;
    })();

    Sequences.Home = Home;
  })(Aneox.Sequences || (Aneox.Sequences = {}));
})(Aneox || (Aneox = {}));