var Aneox;
(function (Aneox) {
  (function (Sequences) {
    'use strict';

    var Wines = (function () {

      var self;

      var wines;
      var winesVisibility;
      var winesMast;
      
      var hasPlayedThroughAnimation;

      function Wines(animations, resources) {
        self = this;
        this.animations = animations;
        this.resources = resources;
        
        hasPlayedThroughAnimation = {};
        
        wines = $('#wines');
        winesMast = $('#wines-mast');
        
        var observer = new MutationObserver(function (mutations) {
          mutations.forEach(function (mutation) {
            if (mutation.attributeName === 'style') {
              var display = wines.css('display');
              var previousWinesVisibility = winesVisibility;
              winesVisibility = display !== 'none';
              
              if (!previousWinesVisibility && display !== 'none') {
                self.handleWindowResize();
              }
            }
          });
        });
        observer.observe(wines[0], { attributes: true });
        
        this.resetAnimations();
      }
      
      Wines.prototype.handleWindowResize = function (bodyWidth, bodyHeight) {
        // if (!winesVisibility) return;
      };
      
      Wines.prototype.resetAnimations = function () {
        hasPlayedThroughAnimation = {};

        //mast
        self.animations.initSectionIntroTimeline($('#wines'));
   

        //part 2

        $('.frame', wines).removeClass('active');

        //part 3
        self.animations.initSubtitleTimeline($('#wines .a3 .ttr'));

      };
      
      Wines.prototype.fastForwardAnimations = function () {
        this.resetAnimations();
        this.mastEnterAnimation(true, 0);
        this.secondPartEnterAnimation(true, 0);
        this.thirdPartEnterAnimation(true, 0);
      };
      
      
      Wines.prototype.mastEnterAnimation = function (force, timescale) {

        // console.log('enter mast');

        if (!force && (!self.animations.enabled || hasPlayedThroughAnimation.mast)) return;
        if (timescale === undefined) timescale = 1;
        
        var timeline = new TimelineMax();

        timeline.call(this.animations.SectionIntroTimeline, [$('#wines')], self.animations, 0 * timescale);
        
        hasPlayedThroughAnimation.mast = true;
      };

      Wines.prototype.secondPartEnterAnimation = function (force, timescale) {
        
        if (!force && (!self.animations.enabled || hasPlayedThroughAnimation.second)) return;
        
        if (timescale === undefined) timescale = 1;
        
        var timeline = new TimelineMax();

        timeline.call(function(){
          $('.frame', wines).addClass('active');
        }, self.animations, 0 * timescale);

        hasPlayedThroughAnimation.second = true;
      };

      Wines.prototype.thirdPartEnterAnimation = function (force, timescale) {
        if (!force && (!self.animations.enabled || hasPlayedThroughAnimation.third)) return;
        
        if (timescale === undefined) timescale = 1;

        var timeline = new TimelineMax();
        
        timeline.call(this.animations.SubtitleTimeline, [$('#wines .a3 .ttr')], self.animations,0 * timescale);

        hasPlayedThroughAnimation.third = true;
      };

      return Wines;
    })();

    Sequences.Wines = Wines;
  })(Aneox.Sequences || (Aneox.Sequences = {}));
})(Aneox || (Aneox = {}));