var Aneox;
(function (Aneox) {
  'use strict';

  var App = (function () {
    
    var self;
      
    var isTouchDevice;
    
    var mainMenuVisibility;
    var scrollRetainCount;
    var lastScrollAction;
    var pageChangeFromScript;
    var shouldShowAdvanceIndicator;
    var advanceIndicatorShown;
    var currentNodeId;
    var currentKeyframeId;
    var currentNodeType;
    var navigationCounter;
    var legalVisibility = false;
    var legalPreviousNode;
    var previousNodeType;
    var initiateLock = false;
    var loadingComplete = false;

    var menuTimeline;

    var formToken;
    var isSubmittingForm = false;


    var data_send = {};

    var cptControlTry = 0;
  


    function App(fonts, locale, router, analytics, animations, wines, morepages) {
      this.fonts = fonts;
      this.locale = locale;
      this.router = router;
      this.analytics = analytics;
      this.animations = animations;
      this.wines = wines;
      this.morepages = morepages;
      
      this.idFromVineyard = {};
      this.data_send = data_send;

      self = this;

      // Quick and dirty desktop/mobile check
      if (screen && screen.availWidth < 1024) window.location.href = 'https://mobile.domaine-kuheiji.fr';

      isTouchDevice = 'ontouchstart' in window;

      lazySizesConfig.preloadAfterLoad = true;

      //block natural scroll
      bodyScrollLock.disableBodyScroll(document.querySelector('#legal'));

      
      // bypass auto scrolling.
      if ('scrollRestoration' in history) {
        history.scrollRestoration = 'manual';
      }

      scrollRetainCount = 1;
      shouldShowAdvanceIndicator = true;
      navigationCounter = 0;
      
      self.initTokenForm();

      var navMenuList = $('#navMenu .menu .contentMenu .principalMenu .item');
      var navMenu = $('#navMenu');
      
      var now = new Date();
      now.setFullYear(now.getFullYear() - 18);
      var nowText = now.toISOString().substring(0, 10).split('-');
      $('#age-check-date').text(nowText[2] + '/' + nowText[1] + '/' + nowText[0]);

      $(navMenuList).on('click', function(){
        decrementScrollRetainCount();
        navMenu.toggleClass('active');
        navMenuList.siblings().removeClass('active');
        $(this).addClass('active');
      });

      if (Cookies.get('acceptsSplashAge-domaine-kuheiji') === 'true'){
        $('.wrapper-question').css('opacity', '0');
      }else{
        $('.wrapper-question').css('opacity', '1');
        incrementScrollRetainCount();
      }


    

      this.router.bind('pageChange', function (path, node, nodeLocale) {

        legalPreviousNode = currentNodeId;
        previousNodeType = currentNodeType;

        currentNodeId = node.id;
        currentKeyframeId = node.id;
        currentNodeType = node.type;

        if(node.id != "legal"){
            navigationCounter++;
        }

        // if(navigationCounter > 1 && !Cookies.get('acceptsCookies-domaine-kuheiji')){
        //     setAcceptCookies();
        //     self.analytics.startAnalytics();
        //     TweenMax.set('#cookies',{ opacity: 0, display: 'none', onComplete: function(){
        //       $('#cookies').remove();
        //     }});
        // }

        if (!Cookies.get('acceptsCookies-domaine-kuheiji')) {    
          TweenMax.to('#cookies', 0.5, { opacity: 1, display: 'block' });
        }else{
            
            if(Cookies.get('acceptsCookies-domaine-kuheiji') === 'true' && !self.analytics.analyticsEnabled){

                self.analytics.startAnalytics();
            }
            
            TweenMax.set('#cookies',{ opacity: 0, display: 'none', onComplete: function(){
              $('#cookies').remove();
            } });
        }

        if (pageChangeFromScript) {

          $('.navListe li a', navMenuList).removeClass('active');
          $('.navListe li a[router-href="' + currentKeyframeId + '"]', navMenuList).addClass('active');
  
          pageChangeFromScript = false;
          return;
        }
        
        self.locale.setLocale(nodeLocale.locale);

        if (node.id === 'legal') {
          self.showLegal();
        } else {
          self.hideLegal();
        }

        if(node.type === "wine-page" && previousNodeType != "wine-page"){
          currentKeyframeId = 'wines';
          self.wines.show(node.id);
          if (lastScrollAction !== undefined) return;

        }else if(node.type === "wine-page" && previousNodeType === "wine-page"){
          currentKeyframeId = 'wines';
          self.wines.swapWine(node.id);
          if (lastScrollAction !== undefined) return;
        }
        else{
          self.wines.close();
        }

        $('.navListe li a', navMenuList).removeClass('active');
        $('.navListe li a[router-href="' + currentKeyframeId + '"]', navMenuList).addClass('active');
        
        

        if (!_.isEmpty(self.animations.sequences)) {
         var time = self.animations.sequences.main.keyframes[currentKeyframeId] || 0;
          
          resetAnimations();

          self.animations.sequences.main.setTime(time);

          self.animations.enabled = true;
          
          lastScrollAction = 'pageChange';

          decrementScrollRetainCount();


          self.animations.sequences[currentKeyframeId].mastEnterAnimation();
          self.closeMenu();

          
        }
      });

      this.locale.bind('localeChange', function (locale) { 

        /* RE-BIND DATA LIST ON LOCAL CHANGE*/
         self.wines.setWinesList();

        $('.lang a, #navMenu .secondaryMenu a').removeClass('active');
        $('.lang a[router-locale="' + locale + '"], #navMenu .secondaryMenu a[router-locale="' + locale + '"]').addClass('active');
        
        self.router.reparseAnchors();

      });

      /* ATTACHE BIND EVENT*/
      self.wines.bind('wineWillShow', incrementScrollRetainCount);
      self.wines.bind('wineWillHide', decrementScrollRetainCount);
      



      this.animations.bind('complete', function (e) {
      
        handleWindowResize();

        // if (Cookies.get('acceptsSplashAge-domaine-kuheiji') === 'true'){
        //   // $('#splash').css('display', 'none');
        //   $('.bandeauTop').css('display', 'flex');
        //   decrementScrollRetainCount();
        // }else{
        //   $('#splash').css('opacity', 0);
        //   // TweenMax.to('#splash', 0.5, { opacity: 1, display: 'block', onComplete: function(){
        //   //   $('.bandeauTop').css('display', 'flex');
        //   // }});
        //   incrementScrollRetainCount();
        // }


        var botAgent = "(bot|google|aolbuild|baidu|bing|msn|duckduckgo|teoma|slurp|yandex|Prerender)";
        var re = new RegExp(botAgent, 'i');
        var bot = re.test(navigator.userAgent);
        
        if(bot === true){
          $('#splash').css('display', 'none');
          start();
        }else{
          var splashElement = document.querySelector('#splash');
          var observerConfig = {
              attributes: true,
              characterdata: true,
              attributeFilter: ['class']
          };
  
          decrementScrollRetainCount();
          self.router.init(true, locale.getLocale());

          var loadingObserver = new MutationObserver(function(mutations){
            var loaderClass = ['lazyloaded', 'accepts-spash-age'];
            mutations.forEach(function(mutation){
                if(_.intersection(mutation.target.classList, loaderClass).length > (loaderClass.length -1)){
                    loadingObserver.disconnect();
                    TweenMax.to('#splash', 0.5, { opacity: 0, onComplete: function(){
                      $('#splash').css('display', 'none');
                      decrementScrollRetainCount();
                      start(); 
                  }});
                  return;
                }
            })
          });
  
          loadingObserver.observe(splashElement, observerConfig);
          
          if (Cookies.get('acceptsSplashAge-domaine-kuheiji')) {
              $('#splash').addClass('accepts-spash-age');
          }else{
              $("#splash .bottom").css('display', 'flex');
          }
        }
      });
      
      // Start website
      var start = function () {

        if(initiateLock === false){

            decrementScrollRetainCount();
            
            // self.router.init(true, locale.getLocale());
            var node = self.router.getNode();
            
            if (node.id !== 'legal') {
              var currentKeyframeId = node.id;

              if (node.type === 'wine-page')currentKeyframeId = 'wines';
              if (node.type === 'wines-page')currentKeyframeId = 'wines';
              if (node.type === 'vintage-page') currentKeyframeId = 'vintages';

              var time = self.animations.sequences.main.keyframes[currentKeyframeId] || 0;
              self.animations.sequences.main.setTime(time);
                
              lastScrollAction = 'pageChange';
            } else {
              self.showLegal();
            }

            initiateLock = true;
        }
      }


      // Window resize handling
      var handleWindowResize = function () {

        var bodyWidth = $(window).innerWidth();
        var bodyHeight = $(window).innerHeight();

        _.forEach($('[screen-height]'), function (element) {
          var sectionHeightElement = $(element);
          var multiplier = parseFloat(sectionHeightElement.attr('screen-height') || '1');
          
          var heightAdd = parseInt(sectionHeightElement.attr('height-add') || '0');
          
          sectionHeightElement.outerHeight(Math.round(bodyHeight * multiplier) + heightAdd);
        });
        
        _.forEach($('[height-set]'), function (element) {
          var sectionHeightElement = $(element);
          var height = parseInt(sectionHeightElement.attr('height-set') || '0');

          var heightAddWidthRatio = bodyWidth *  parseFloat(sectionHeightElement.attr('height-add-width-ratio') || '0');
          
          sectionHeightElement.outerHeight(height + heightAddWidthRatio);
        });
        
        _.forEach($('[height-ratio]'), function(element){
            var sectionHeightElement = $(element);
            var multiplier = parseFloat(sectionHeightElement.attr('height-ratio') || '1');

            var heightAdd = parseInt(sectionHeightElement.attr('height-add') || '0');

            var heightAddWidthRatio = bodyWidth *  parseFloat(sectionHeightElement.attr('height-add-width-ratio') || '0');

            sectionHeightElement.outerHeight(Math.round(bodyHeight * multiplier) + heightAdd + heightAddWidthRatio);
        });


        _.forEach($('[height-by-width-ratio]'), function(element){
          var sectionHeightElement = $(element);
          var multiplier = parseFloat(sectionHeightElement.attr('height-by-width-ratio') || '1');

          var heightAdd = parseInt(sectionHeightElement.attr('height-add') || '0');

          sectionHeightElement.outerHeight(Math.round(bodyWidth * multiplier) + heightAdd);
      });



        _.forEach(self.animations.sequences, function (sequence) { sequence.handleWindowResize(bodyWidth, bodyHeight); });
        
        self.wines.handleWindowResize(bodyWidth, bodyHeight);

        lastScrollAction = 'windowResize';
      };

      var throttledWindowResizeHandler = _.throttle(handleWindowResize, 500, {leading: false, trailing: true});
      $(window).resize(throttledWindowResizeHandler);
      
      // Wheel handling
      var position = 25;
      var scrollEndTimeout;

      $(window).keydown(function (e){        
        switch(e.keyCode){
            case 38:

                $(window).trigger({
                  type: "mousewheel",
                  wheelDelta: 1,
                  deltaY: 5,
                  deltaFactor: 4
                });
                break;
            case 40:
                $(window).trigger({
                  type: "mousewheel",
                  wheelDelta: -1,
                  deltaY: -5,
                  deltaFactor: 4
                });

                break;
        }
      });


      $(window).on('mousewheel', function (event) {
        if (scrollRetainCount !== 0) return;

        if (_.includes(['pageChange','windowResize','scrollToKeyframe'], lastScrollAction))
          position = self.animations.sequences.main.timeline.time() / self.animations.sequences.main.duration * self.animations.sequences.main.scrollHeight;
          
        position -= event.deltaY * event.deltaFactor * 1.5 * self.animations.scrollSpeed;
        position = Math.max(0, Math.min(self.animations.sequences.main.scrollHeight, position));

        var scrollPercent = position / self.animations.sequences.main.scrollHeight;
        var time = scrollPercent * self.animations.sequences.main.duration;

        self.animations.sequences.main.setTime(time, 0.25);
        checkKeyframes.call(self, time);
        
        lastScrollAction = 'mouseWheel';

        scrollEndTimeout = setTimeout(function() {
          position -= event.deltaY * event.deltaFactor * 1.5 * self.animations.scrollSpeed;
          position = Math.max(0, Math.min(self.animations.sequences.main.scrollHeight, position));

          scrollPercent = position / self.animations.sequences.main.scrollHeight;
          time = scrollPercent * self.animations.sequences.main.duration;

          self.animations.sequences.main.setTime(time, 1, Power2.easeOut);
          checkKeyframes.call(self, time);
        }, 100);
      });
      
      // Touch handling
      var lastPanDirectionX;
      var lastPanDeltaX;
      var lastPanVelocityX;
      var targetPanId;

      if (isTouchDevice) {
        var mc = new Hammer.Manager(document.body);
        mc.add(new Hammer.Pan({ threshold: 0, pointers: 0, direction: Hammer.DIRECTION_VERTICAL }));
        mc.on('panstart panmove panend', function (event) {
          if (scrollRetainCount !== 0) return;
          
          // if (event.type === 'panstart') targetPanId = event.srcEvent.target.getAttribute('id');
          // if (targetPanId === 'scrollbar' || targetPanId === 'scroller') return;
          
          if (event.type === 'panstart') lastPanDeltaY = 0;
          
          if (_.includes(['pageChange','windowResize','scrollToKeyframe'], lastScrollAction))
            position = self.animations.sequences.main.timeline.time() / self.animations.sequences.main.duration * self.animations.sequences.main.scrollHeight;
          
          var absoluteVelocityY = Math.abs(lastPanVelocityY);
          var scrollDuration;
          
          if (event.type === 'panend' && absoluteVelocityY > 0.5) {
            position -= absoluteVelocityY * lastPanDirectionY * 200;
            scrollDuration = absoluteVelocityY / 3;
          } else {
            position -= event.deltaY - lastPanDeltaY;
          }
          
          position = Math.max(0, Math.min(self.animations.sequences.main.scrollHeight, position));
  
          lastPanDirectionY = (event.deltaY - lastPanDeltaY) >= 0 ? 1 : -1;
          lastPanDeltaY = event.deltaY;
          lastPanVelocityY = event.velocityY;
  
          var scrollPercent = position / self.animations.sequences.main.scrollHeight;
          var time =  scrollPercent * self.animations.sequences.main.duration;
        
          self.animations.sequences.main.setTime(time, scrollDuration);
          checkKeyframes.call(self, time);
          
          lastScrollAction = 'pan';
        });
      }
    }
    
    var incrementScrollRetainCount = function () {
      scrollRetainCount += 1;
    };
    
    var decrementScrollRetainCount = function () {
      scrollRetainCount -= 1;
      scrollRetainCount = Math.max(scrollRetainCount, 0);
    };
    
    var setPage = function (nodeId, isNotKeyframe) {

      if (nodeId === this.router.getNode()) return;
      
      var path = this.router.getNodePath(nodeId, this.locale.getLocale());
      if (path === undefined) return;
      
      pageChangeFromScript = isNotKeyframe ? false : true;
      this.router.setPath(path);
    }
    
    var checkKeyframes = _.debounce(function (time) {
      var keyframe;
      var keyframeTime = 0;
      
      _.forOwn(this.animations.sequences.main.keyframes, function (value, key) {
        if (time >= value && value >= keyframeTime) {
          keyframe = key;
          keyframeTime = value;
        }
      });
  

      if (!keyframe) return;
      setPage.call(this, keyframe);
    }, 250);


    App.prototype.initTokenForm = function(token){
      $.get(rootPath + "token.php").done(function(data){formToken = data;});
    };



    App.prototype.closeWines = function(event){
      if (event && event.preventDefault) event.preventDefault();
      self.wines.close();
      setPage.call(this, 'wines');
      self.animations.enabled = true;
    }

    App.prototype.closeMorePage = function(event){
      this.morepages.close(currentNodeId);
      setPage.call(this, currentKeyframeId);
      self.animations.enabled = true;
      if (event && event.preventDefault) event.preventDefault();
    }

   
      
    App.prototype.openMenu = function(event){
      if (mainMenuVisibility) return;
      mainMenuVisibility = true;

      incrementScrollRetainCount();

      $(navMenu).addClass('active');
      // TweenMax.to("#navMenu", .5, {opacity: 1, display: 'block'});
      if (event && event.preventDefault) event.preventDefault();

    }

    App.prototype.closeMenu = function(event){
      if (!mainMenuVisibility) return;
      mainMenuVisibility = false;

      decrementScrollRetainCount();
      $("#navMenu").removeClass('active');
      // TweenMax.to("#navMenu", .5, {opacity: 0, onComplete: function(e){
      //   $("#navMenu").css('display', 'none');
      // }});
      if (event && event.preventDefault) event.preventDefault();

    }


     App.prototype.showLegal = function (event) {
       if (legalVisibility) return;
       legalVisibility = true;
       incrementScrollRetainCount();
       TweenMax.fromTo('#legal', 0.5, { display: 'block', opacity: 0 }, { opacity: 1 });
       if (event && event.preventDefault) event.preventDefault();
     };
    
     App.prototype.hideLegal = function (event) {
       if (!legalVisibility) return;
       legalVisibility = false;
       decrementScrollRetainCount();
      
       setPage.call(this, legalPreviousNode);
       TweenMax.fromTo('#legal', 0.5, { opacity: 1 }, { opacity: 0, display: 'none' });
       
     };
    
    App.prototype.primaryLanguageClick = function (event) {
      if (mainMenuVisibility) return;
      
      if (event && event.preventDefault) event.preventDefault();
      
      var newPath = this.router.getNodePath(currentNodeId, this.locale.getLocale() === "fr" ? "en" : "fr");
      this.router.setPath(newPath);
    };

    App.prototype.gotoDomaine = function(event){
      if (event && event.preventDefault) event.preventDefault();
      self.animations.sequences.main.setTime(1, 1);
    }

    App.prototype.acceptCookies = function() {           
        setAcceptCookies();
        self.analytics.startAnalytics();
        TweenMax.to('#cookies', 0.5, { opacity: 0, display: 'none' });
    };
    
    App.prototype.deniedCookies = function(){
        setDeniedCookies();
        TweenMax.to('#cookies', 0.5, { opacity: 0, display: 'none' });
    }
    
    App.prototype.acceptSplashAge = function() {
        Cookies.set('acceptsSplashAge-domaine-kuheiji', 'true', { expires: 365 });
        $('#splash').addClass('accepts-spash-age');
        // TweenMax.to('#splash', 0.5, { opacity: 0 , onComplete: function(){
        //   $('#splash').css('display', 'none');
        //   resetAnimations();
        //   self.animations.enabled = true;
        //   self.animations.sequences[currentKeyframeId].mastEnterAnimation();
        //   //showAdvanceIndicator();
        //   decrementScrollRetainCount();
        // }});
      
    };

    App.prototype.refuseSplashAge = function() {
        window.location.href = 'https://google.fr';
    };


    var setAcceptCookies = function(){
        Cookies.set('acceptsCookies-domaine-kuheiji', 'true' , { expires: 150 });
    }
    
    var setDeniedCookies = function(){
        Cookies.set('acceptsCookies-domaine-kuheiji', 'false', { expires: 1 });
    }
    
    var resetAnimations = function () {

      if (self.animations.orderedSequences === undefined) return;

      var i;
      var sequence;
      
      var activeSequenceIndex = _.indexOf(self.animations.orderedSequences, self.animations.sequences[currentKeyframeId]);

      for (i = 0; i < activeSequenceIndex; i += 1) {
        sequence = self.animations.orderedSequences[i];
        if (sequence.fastForwardAnimations) sequence.fastForwardAnimations();
      }
      
      for (i; i < self.animations.orderedSequences.length; i += 1) {
        sequence = self.animations.orderedSequences[i];
        if (sequence.resetAnimations) sequence.resetAnimations();
      }
    };

    return App;
  })();

  Aneox.App = App;
})(Aneox || (Aneox = {}));