var Aneox;
(function (Aneox) {
  'use strict';

  var Animations = (function () {

    function Animations(locale) {
      var self = this;

      var sequences;
      var orderedSequences;

      this.timeline = new TimelineMax();
      
      Object.defineProperties(this, {
        sequences: {
          get: function () {
            return sequences;
          }
        },
        orderedSequences: {
          get: function () {
            return orderedSequences;
          }
        }
      });
      
      this.enabled = false;

      var isTouchDevice = 'ontouchstart' in window;
      this.scrollSpeed = isTouchDevice ? 1.0 : 0.1;
      


      self.openOverlay = function(element) {
        //element.addClass('active');

        //TweenMax.set(element, );
        TweenMax.to(element, 0.5, {display: 'block', opacity:1});

        /* TweenMax.to('#background', maskDuration, {
          display: 'block',
          y: 0,
          ease: maskEase
        });
        TweenMax.to('#background-logo', maskDuration, {
          y: 0,
          ease: maskEase
        });
        TweenMax.to(element.add(element.children('.overlay-wrapper')), maskDuration, {
          display: 'block',
          y: 0,
          ease: maskEase,
          delay: maskDuration * .9
        }); */

        /* var gallery = element.children('#gallery-list');
        if (gallery.length > 0) {
          TweenMax.to(gallery, {
            y: 0,
            ease: maskEase,
            delay: maskDuration * .9
          });
        } */
      };

      self.initSubtitleTimeline = function(element){
        
        var lines = element.find('.line');
        
        TweenMax.set(lines,  {y:'110%', force3D: true, immediateRender: true });
        TweenMax.set(element.find('.hr'),  {scaleX:0, force3D: true, immediateRender: true });
      }

      self.initSectionIntroTimeline = function(element){

        var title = element.find('.ttr');
        var subtitle = element.find('.sttr');
        var lv = element.find('.lv');
        var nav = element.find('.verticalNav .list');

        TweenMax.set(subtitle, {opacity: 0, y:150});
        TweenMax.set(title, {opacity: 0, x:-150});
        TweenMax.set(lv, {height:"0%"});
        TweenMax.set(nav, {y:100, opacity:0});

        // TweenMax.set(line1, {y: '110%', force3D: true, immediateRender: true});
        // TweenMax.set(line2, {y: '-135%', force3D: true, immediateRender: true});
        // TweenMax.set(separator, {x: '-110%', force3D: true, immediateRender: true});

      }


      self.SubtitleTimeline = function(element) {
        // var timeline = new TimelineMax();

        var ease = Power2.easeOut;
        var duration = 1;
        var stagger = .1;

        var lines = element.find('.line');

        TweenMax.staggerTo(lines, duration, {
          y: "0%",
          ease: ease
        }, stagger, function() {
          setTimeout(function() {
            lines.removeAttr('style');
          }, 500);
        });
        TweenMax.to(element.find('.hr'), duration, {
          scaleX: 1,
          ease: ease
        }, '-=' + (duration - stagger));


        //  return timeline;
      };

      self.SectionIntroTimeline = function(element){

        var timeline = new TimelineMax();

        var title = element.find('.ttr');
        var subtitle = element.find('.sttr');
        var lv = element.find('.lv');
        var nav = element.find('.verticalNav .list');

        var ease = Power2.easeOut;

        TweenMax.to(title, 1, {opacity: 1, x:0, force3D: true, delay: 0 });
        TweenMax.to(subtitle, 1, {opacity: 1, y:0, force3D: true, delay: 0  });
        TweenMax.to(lv, 1, {height: "100%", force3D: true, delay: 0  });
        TweenMax.to(nav, 1, {y: 0, opacity:1, force3D: true, delay: 0  });


      }

      self.closeOverlay = function(element, onComplete) {
        element.removeClass('active');

        //TweenMax.set(element, {display: 'none', opacity:0});
        TweenMax.to(element, 0.5, {display: 'none', opacity:0});

        /* TweenMax.to(element, maskDuration, {
          display: 'none',
          y: -bodyHeight,
          ease: maskEase
        });
        TweenMax.to(element.children('.overlay-wrapper'), maskDuration, {
          y: bodyHeight / 2,
          ease: maskEase
        });
        TweenMax.to('#background', maskDuration, {
          display: 'none',
          y: -bodyHeight,
          ease: maskEase,
          delay: maskDuration * .9,
          onComplete: onComplete
        });
        TweenMax.to('#background-logo', maskDuration, {
          y: bodyHeight,
          ease: maskEase,
          delay: maskDuration * .9
        }); */
        /* var gallery = element.children('#gallery-list');
        if (gallery.length > 0) {
          TweenMax.to(gallery, {
            y: bodyHeight,
            ease: maskEase
          });
        } */
      };

      var numberLoaded = 0;
      var totalNumber = 14;


      var loader = new PIXI.Loader();
      loader
      .add('assets/imgs/bas-relief.png')
      // .add('domaine-1', 'assets/domaine/pic1.jpg')
      // .add('vine-1', 'assets/vigne/pic1.jpg')
      // .add('vine-2', 'assets/vigne/bgSaison.jpg')
      // .add('vine-3', 'assets/vigne/nuage.jpg')
      // .add('vine-4', 'assets/vigne/bgGreen.jpg')
      // .add('winemaking-1', 'assets/vinification/pic1.jpg')
      // .add('winemaking-2', 'assets/vinification/bgSaison.jpg')
      // .add('wines-1', 'assets/nosVins/pic1.jpg')
      // .add('wines-2', 'assets/nosVins/bgSaison.jpg')
      // .add('wines-3', 'assets/nosVins/pic3.jpg')
      .once('complete', function (loader, resources) {


        sequences = {
          'main': new Aneox.Sequences.Main(self, resources, locale),
          'home': new Aneox.Sequences.Home(self, resources, locale),
          'estate': new Aneox.Sequences.Estate(self, resources, locale),
          'philosophy': new Aneox.Sequences.Philosophy(self, resources, locale),
          'vignard': new Aneox.Sequences.Vignard(self, resources, locale),
          'wines': new Aneox.Sequences.Wines(self, resources, locale),
          'contact': new Aneox.Sequences.Contact(self, resources, locale)
        };
        
        orderedSequences = [
          sequences['home'],
          sequences['estate'],
          sequences['philosophy'],
          sequences['vignard'],
          sequences['wines'],
          sequences['contact']
        ];
        
        self.trigger('complete');
      })
      .on('load', function(loader, ressource){
        numberLoaded++;
        var proportion = numberLoaded / totalNumber;
        var percentage = (100 * proportion).toFixed(0);
        $('#preloader-value').text(percentage);
        TweenMax.to('#preloader-progress', 1, { scaleX: proportion });
      })
      .on('error', function(error, loader, resource) {
        console.log('error loading file', resource.name, error.message);
      })
      .load();
    }
    
    MicroEvent.mixin(Animations);

    return Animations;
  })();

  Aneox.Animations = Animations;
})(Aneox || (Aneox = {}));