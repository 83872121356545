var Aneox;
(function (Aneox) {
  'use strict';

  var MorePages = (function () {

    var isOpen;
    
    var importerContinentElement;
    var importerContinentBackgroundGlobalElement;
    var importerContinentBackgroundZoomElement;
    var importerContinentListElement;
    var importerCountryListElement;
    var importerContinentDetailsElement;
    var nameElement;

    var continent;
    var continentLocale;
    
    var activeSection;

    function MorePages(locale) {
      this.locale = locale;

      importerContinentElement = $('#importer-continent');
      importerContinentBackgroundGlobalElement = $('#importer-continent-background-global');
      importerContinentBackgroundZoomElement = $('#importer-continent-background-zoom');
      importerCountryListElement = $('#importer-country-list');
      importerContinentListElement = $('#importer-continent-list');
      nameElement = $('#importer-continent-name');
      importerContinentDetailsElement = $('#importer-continent-details');
    }


    MorePages.prototype.show = function (moreId) {
      if(isOpen) return;
      isOpen = true;

      TweenMax.to('#'+moreId, .5, {display: 'block', opacity: 1});


      this.trigger('morepagesWillShow');
    };
    
    MorePages.prototype.close = function (moreId) {
      if(!isOpen) return;  

      TweenMax.to('#'+moreId, .5, {display: 'none', opacity: 0});

      isOpen = false;
      this.trigger('morepagesWillhide');
    };

    
    MorePages.prototype.handleWindowResize = function(bodyWidth, bodyHeight) {
      if (!isOpen) {
        //TweenMax.set('#gallery-list', { y: bodyHeight });
      }
    }

    MicroEvent.mixin(MorePages);

    return MorePages;
  })();

  Aneox.MorePages = MorePages;
})(Aneox || (Aneox = {}));