var Aneox;
(function (Aneox) {
  (function (Library) {
    'use strict';

    var Locale = (function () {

      var translatables;
      var locale;

      function Locale() {
        // locale = Aneox.Data.Config.rootLocale;

        if(!_.isEmpty(window.browserLocale)) locale = browserLocale;
        locale = (!_.isEmpty(window.browserLocale))? window.browserLocale : Aneox.Data.Config.rootLocale;

        translatables = $('[translate]');

        _.forEach(translatables, function (element) {
          var translatable = $(element);
          translatable.data('translatable', translatable.html());

          if (locale === Aneox.Data.Config.rootLocale) {
            translatable.html(translatable.data('translatable'));
          } else {
            var translation = Aneox.Texts[locale] && Aneox.Texts[locale][translatable.data('translatable')];
            if (_.isString(translation) && !_.isEmpty(translation)) translatable.html(translation);
          }

        });
        
        $('html').attr('lang', locale);
        $('body').addClass('language-' + locale);
        
        setTimeout(this.trigger.bind(this, 'localeChange', locale));
      }

      Locale.prototype.setLocale = function (newLocale) {
        if (newLocale === locale) return;

        $('html').attr('lang', newLocale);
        $('body').removeClass('language-' + locale)
        $('body').addClass('language-' + newLocale);
        
        locale = newLocale;

        _.forEach(translatables, function (element) {
          var translatable = $(element);
          if (locale === Aneox.Data.Config.rootLocale) {
            translatable.html(translatable.data('translatable'));
          } else {
            var translation = Aneox.Texts[locale] && Aneox.Texts[locale][translatable.data('translatable')];
            if (_.isString(translation) && !_.isEmpty(translation)) translatable.html(translation);
          }
        });
        
        this.trigger('localeChange', locale);
      };

      Locale.prototype.getLocale = function () {
        return locale;
      };

      Locale.prototype.getTranslation = function(text, locale){
        if(Aneox.Data.Config.rootLocale === locale) return text;
        else return Aneox.Texts[locale] && Aneox.Texts[locale][text];
      } 

      MicroEvent.mixin(Locale);
      
      return Locale;
    })();

    Library.Locale = Locale;
  })(Aneox.Library || (Aneox.Library = {}));
})(Aneox || (Aneox = {}));