var Aneox;
(function (Aneox) {
  (function (Library) {
    'use strict';

    var Analytics = (function () {

      var self;


      function Analytics(router) {
        this.analyticsEnabled = false;
        this.router = router;
        self = this;
        
      }
        
      Analytics.prototype.startAnalytics = function(){
        if (_.isEmpty(Aneox.Data.Config.analytics)) return;
          
        if (!_.isEmpty(Aneox.Data.Config.analytics.google)) {
          addScript("https://www.googletagmanager.com/gtag/js?id="+Aneox.Data.Config.analytics.google);

            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', Aneox.Data.Config.analytics.google);

            self.analyticsEnabled = true;
        }
      
        this.router.bind('pageChange', function (path, node, nodeLocale) {
            if (self.analyticsEnabled) {

              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('event', 'page_view', {
                page_title: path.title,
                page_path: path.path,
                send_to: Aneox.Data.Config.analytics.google
              })
            }
        });
          
      }

      function addScript(src) {
        var s = document.createElement('script');
        s.setAttribute('src', src);
        s.setAttribute("async", "async");
        s.setAttribute("defer", "defer");
        document.body.appendChild(s);
      }

      MicroEvent.mixin(Analytics);

      return Analytics;
    })();

    Library.Analytics = Analytics;
  })(Aneox.Library || (Aneox.Library = {}));
})(Aneox || (Aneox = {}));