var Aneox;
(function (Aneox) {
  (function (Sequences) {
    'use strict';

    var Contact = (function () {

      var self;

      var contact;
      var contactVisibility;
      var contactMast;
      
      var hasPlayedThroughAnimation;

      function Contact(animations, resources) {
        self = this;
        this.animations = animations;
        this.resources = resources;
        
        hasPlayedThroughAnimation = {};
        
        contact = $('#contact');
        contactMast = $('#contact-mast');
        
        var observer = new MutationObserver(function (mutations) {
          mutations.forEach(function (mutation) {
            if (mutation.attributeName === 'style') {
              var display = contact.css('display');
              var previousContactVisibility = contactVisibility;
              contactVisibility = display !== 'none';
              
              if (!previousContactVisibility && display !== 'none') {
                self.handleWindowResize();
              }
            }
          });
        });
        observer.observe(contact[0], { attributes: true });
        
        this.resetAnimations();
      }
      
      Contact.prototype.handleWindowResize = function (bodyWidth, bodyHeight) {
        // if (!contactVisibility) return;
      };
      
      Contact.prototype.resetAnimations = function () {
        hasPlayedThroughAnimation = {};

         //mast
         self.animations.initSectionIntroTimeline($('#contact-part1 .contentTtr .ttr'));

         //part1
        // TweenMax.set("#contact-part2 .contactAdress .bg", {opacity:0, y: 100, force3D: true, immediateRender:true});
        // TweenMax.set("#contact-part2 .contactForm, #contact-part2 .contactAdress .bg", {opacity:0, y: 100, force3D: true, immediateRender:true});
        // TweenMax.set("#contact-part2 .contactAdress .adress-wrapper", {opacity:0, y: "110%", force3D: true, immediateRender:true});
        // self.animations.initSubtitleTimeline($('#contact-part2 .contactAdress .title'));
      };
      
      Contact.prototype.fastForwardAnimations = function () {
        this.resetAnimations();
        this.mastEnterAnimation(true, 0);
        this.secondPartEnterAnimation(true, 0);
        this.thirdPartEnterAnimation(true, 0);
      };
      
      Contact.prototype.mastEnterAnimation = function (force, timescale) {
        if (!force && (!self.animations.enabled || hasPlayedThroughAnimation.mast)) return;
        
        if (timescale === undefined) timescale = 1;
        
        var timeline = new TimelineMax();

        // timeline.call(this.animations.SectionIntroTimeline, [$('#contact-part1 .contentTtr .ttr')], self.animations, 0 * timescale);

        hasPlayedThroughAnimation.mast = true;
      };

      Contact.prototype.secondPartEnterAnimation = function (force, timescale) {
        if (!force && (!self.animations.enabled || hasPlayedThroughAnimation.second)) return;
        
        if (timescale === undefined) timescale = 1;
        
        var timeline = new TimelineMax();
        
        // timeline.to("#contact-part2 .contactForm, #contact-part2 .contactAdress .bg",1 , {opacity:1, y: 0, force3D: true}, 0 * timescale);


        hasPlayedThroughAnimation.second = true;
      };

      Contact.prototype.thirdPartEnterAnimation = function (force, timescale) {
        if (!force && (!self.animations.enabled || hasPlayedThroughAnimation.third)) return;
        
        if (timescale === undefined) timescale = 1;
        
        var timeline = new TimelineMax();
       
        // timeline.call(this.animations.SubtitleTimeline, [$('#contact-part2 .contactAdress .title')], self.animations,  0 * timescale);
        // timeline.to("#contact-part2 .contactAdress .adress-wrapper",1 , {opacity:1, y: "0%", force3D: true}, .5 * timescale);


        hasPlayedThroughAnimation.third = true;
      };

      return Contact;
    })();

    Sequences.Contact = Contact;
  })(Aneox.Sequences || (Aneox.Sequences = {}));
})(Aneox || (Aneox = {}));